import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { RestaurantType } from '../../../redux/restaurantSlice'
import { fetchRestaurantClubMembers, setCurrentBusinessID, setSelectedMembers } from '../../../redux/smsCampaignSlice'
import { MemberClubType } from './types'
const MembersTable = ({
    clubMembers, onCheckMember, selectedMembers, onCheckAllMembers, setStep }:
    { clubMembers: any | null, onCheckMember: ({ memberId, clearSelection }: { memberId: number, clearSelection: boolean | null }) => void, selectedMembers: Array<Number>, onCheckAllMembers: () => void, setStep: (index: number) => void }) => {
    const MembersClubArray = useSelector((state: RootState) => state.smsCampaign.MembersClub)
    const FetchingMembersClub = useSelector((state: RootState) => state.smsCampaign.FetchingMembersClub)
    
    const nextStep = 1
    const auth = useSelector((state: RootState) => state.auth)
    const dispatch = useDispatch()
    const [SelectedBusiness, setSelectedBusiness] = useState<string | number | null>("")
    useEffect(() => {
        console.log("selectedMembers", selectedMembers);
        onCheckMember({ memberId: 0, clearSelection: true })
        dispatch(setCurrentBusinessID(SelectedBusiness))
        dispatch(fetchRestaurantClubMembers({ restaurantId: SelectedBusiness }))
    }, [SelectedBusiness])
    return (
        <div>
            <h4 style={{ fontWeight: 'bold' }} className="">{i18n.t("select-club-members")}</h4>
            <div className="d-flex justify-content-between align-content-center">
                <p>{i18n.t("selected-members-count").format(selectedMembers.length?.toString())}</p>
                <button onClick={() => setStep(nextStep)} style={{ alignSelf: 'flex-end' }} type="button" className="mt-4 btn btn-primary btn-rounded btn-fw">
                    {i18n.t("next")}
                </button>
            </div>
            <div className="form-group">
                <label htmlFor="exampleSelectGender">{i18n.t("business")}</label>
                <select className="form-control" id="exampleSelectGender" onChange={(e) => {
                    console.log("onSelected Business", e.target.value)
                    auth.user?.restaurants.forEach((restaurant: RestaurantType) => {
                        //@ts-ignore
                        if (restaurant.id == e.target.value)
                            setSelectedBusiness(restaurant.id)
                    })

                }}>
                    {auth.user?.restaurants && auth.user?.restaurants.map((restaurant: RestaurantType) => {
                        return <option key={restaurant.id} value={restaurant.id}>{restaurant.name}</option>
                    })}
                </select>
            </div>
            {SelectedBusiness == "" ? <p style={{ textAlign: "center", fontSize: 28 }}>Select a business</p> :
                <div className="table-responsive">
                    {
                        FetchingMembersClub ? <p></p> : <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="form-check form-check-primary">
                                            <label className="form-check-label">
                                                <input
                                                    checked={MembersClubArray.length === selectedMembers.length}
                                                    onClick={(e) => {
                                                        onCheckAllMembers();
                                                    }}
                                                    onChange={() => { }}
                                                    type="radio"
                                                    className="form-check-input"
                                                />
                                                <i className="input-helper" />
                                            </label>
                                        </div>
                                    </th>
                                    <th>#</th>
                                    <th>{i18n.t("telephone")}</th>
                                    <th>{i18n.t("username")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (MembersClubArray != null && MembersClubArray.length > 0) && MembersClubArray.map((member: any, index: any) => {
                                        return <tr key={index}>
                                            <td>
                                                <div className="form-check form-check-primary">
                                                    <label className="form-check-label">
                                                        <input
                                                            checked={selectedMembers.includes(member.id)}
                                                            onClick={() => { onCheckMember({ memberId: member.id, clearSelection: false }) }}
                                                            onChange={() => { }}
                                                            type="radio"
                                                            className="form-check-input"
                                                        />
                                                        <i className="input-helper" />
                                                    </label>
                                                </div></td>
                                            <td className="py-1">
                                                <img src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt="image" />
                                            </td>
                                            <td>{member.users_permissions_user.phone}</td>
                                            <td>{member.users_permissions_user.email}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>
            }
            {
                <div className="d-flex align-content-center justify-content-end">
                    <button
                        disabled={(selectedMembers.length <= 0)}
                        onClick={() => {
                            console.log("selectedMembers", selectedMembers);
                            dispatch(setSelectedMembers(selectedMembers))
                            setStep(nextStep)
                        }} type="button" className="mt-4 btn btn-primary btn-rounded btn-fw">
                        {i18n.t("next")}
                    </button>
                </div>
            }

        </div>
    )
}

export default MembersTable
