
import React, { useContext, useEffect, useState } from 'react'
import { RestaurantContext } from '../../RestaurantProvider';
import { api } from '../../utils/api';
import { MabaliColors } from '../../utils/Colors';

import { TopItemType } from './TopItems.types';

const TopItems = () => {

  const restaurantCtx = useContext(RestaurantContext);
  const [TopItems, setTopItems] = useState<TopItemType[]>([]);

  const getTopItems = () => {
    api.get("/restaurants/get-top-items?restaurants=" + restaurantCtx.ActiveRestaurant?.id)
      .then(({ data }: { data: TopItemType[] }) => {
        console.log('getTopItems data', data)
        setTopItems(data)
      })
  }

  useEffect(() => {
    if (restaurantCtx.ActiveRestaurant)
      getTopItems();
  }, [restaurantCtx.ActiveRestaurant]);
  return (
    <div className="col-sm-3 grid-margin stretch-card" style={{
      display: 'flex',
      flexDirection: 'column',
      color: MabaliColors.pink
    }}>
      <span style={{
        textDecoration: 'underline',
        fontSize: 22,
      }}>הנמכרים ביותר</span>

      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${MabaliColors.pink}`,
        padding : 10
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          {TopItems.map((item, key) => {
            return <span style={{
              color: MabaliColors.black
            }} key={key}>
              <b>({item.amount})</b>
            </span>
          })}
        </div>
        <div style={{
          paddingTop: 10,
          paddingBottom: 10,
          width: 1,
          height: "100%",
          marginLeft: 10,
          marginRight: 10,
          border: '1px dashed ' + MabaliColors.pink
        }} />
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          {TopItems.map((item, key) => {
            return <span style={{
              color: MabaliColors.black
            }} key={key}>
              {item.name_he}
            </span>
          })}
        </div>
      </div>
    </div>
  )
}

export default TopItems