
import { Dictionary } from '@reduxjs/toolkit';
import React, { useContext, useEffect, useState } from 'react'
import { RestaurantContext } from '../../RestaurantProvider';
import { api } from '../../utils/api';
import { MabaliColors } from '../../utils/Colors';
import { OrderDataType } from '../OrdersFinancial/orders-financial.types';

const ReturningCustomersOrderPct = ({ orders }: { orders: number }) => {
  const [Data, setData] = useState<any[]>([]);
  const restaurantCtx = useContext(RestaurantContext);

  const [Amount, setAmount] = useState<number>(0);
  const [ReturningUsers, setReturningUsers] = useState<number>(0);
  const [Avg, setAvg] = useState<number>(0);

  const getReturningOrderOfMonth = () => {
    api.get(`/restaurants/returning-order?restaurants=${restaurantCtx.ActiveRestaurant?.id}`)
      .then(({ data }: { data: OrderDataType[] }) => {
        console.log("gotReturningOrderOfMonth", data)
        setData(data)
      })
      .catch((e) => {
        console.warn('could not get getReturningOrderOfMonth', e.response.data);

      })
  }

  useEffect(() => {
    const uniqueUsersMap: Dictionary<boolean> = {}

    if (Data.length) {
      setReturningUsers(Data.reduce((count, row) => {
        if (!uniqueUsersMap[row.id]) {
          uniqueUsersMap[row.id] = true;
          return count + 1
        }
        else
          return count
      }, 0) / Data.length)

      setAvg(Data.reduce((a, row) => {
        return a + row.total
      }, 0) / Data.length)
      setAmount(Data.length);
    }

  }, [Data]);

  useEffect(() => {
    if (restaurantCtx.ActiveRestaurant)
      getReturningOrderOfMonth();
  }, [restaurantCtx.ActiveRestaurant]);

  const textStyle = {
    marginLeft: 10,
    marginRight: 10,
  }
  return (<div style={{
    display: 'flex',
    flexDirection: 'row'
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 10,
      marginRight: 10,
      justifyContent: 'center'
    }}>
      <span>הזמנות</span>
      <span>אחוז מכלל ההזמנות</span>
      <span>ממוצע להזמנה</span>

    </div>
    <div style={{
      paddingTop: 10,
      paddingBottom: 10,
      width: 1,
      border: '1px dashed ' + MabaliColors.pink
    }} />
    <div style={{
      marginLeft: 5,
      marginRight: 5,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <span style={textStyle}>{ReturningUsers}</span>
      <span style={textStyle}>{Amount && orders ? Amount / orders * 100 : 0}%</span>
      <span style={textStyle}>₪{Avg}</span>
    </div>
  </div>
  )
}

export default ReturningCustomersOrderPct