

import React, { useState } from 'react'
import { RestaurantType } from './redux/restaurantSlice';
import { api } from './utils/api';


const RestaurantContext = React.createContext<{
  ActiveRestaurant: RestaurantType | undefined,
  setActiveRestaurant: React.Dispatch<React.SetStateAction<RestaurantType | undefined>>
}>({
  ActiveRestaurant: undefined,
  setActiveRestaurant: () => {}
});

export type UserType = {
  email: string;
}

const RestaurantProvider = ({ children }: { children: JSX.Element }) => {
  const [ActiveRestaurant, setActiveRestaurant] = useState<RestaurantType>();

  return (
    <RestaurantContext.Provider value={{
      setActiveRestaurant,
      ActiveRestaurant
    }}>
      {children}
    </RestaurantContext.Provider>
  )
}

export {
  RestaurantContext,
  RestaurantProvider
}