import { Dictionary } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { RestaurantContext } from '../../RestaurantProvider'
import { api } from '../../utils/api'
import { MabaliColors } from '../../utils/Colors'
import Data from '../../utils/Data'
import { ReviewType } from '../Reviews/Review.types'

const SalesThisMonth = ({ total, totalLastMonth }: { total: number, totalLastMonth: number }) => {
  const restaurantCtx = useContext(RestaurantContext);
  const [Data, setData] = useState<any[]>([]);
  let startOfMonth = dayjs().startOf('month')
  let endOfMonth = dayjs().endOf('month')
  const [AvgReview, setAvgReview] = useState<number>(0);

  const getReviewsThisMonth = () => {
    api.get(`/reviews?created_at_gte=${startOfMonth.format('YYYY-MM-DD')}&created_at_lte=${endOfMonth.format('YYYY-MM-DD')}&restaurant=${restaurantCtx.ActiveRestaurant?.id}`)
      .then(({ data }: { data: ReviewType[] }) => {
        console.log("getReviewsThisMonth data", data)
        setData(data)
      })
      .catch(e => {
        console.warn('could not get orders this month', e.response.data);

      })
  }

  useEffect(() => {

    if (Data.length) {
      setAvgReview(Data.reduce((accumulated, review) => {
        return accumulated + review.evaluation
      }, 0) / Data.length)
    }
  }, [Data]);

  useEffect(() => {

    if (restaurantCtx.ActiveRestaurant) {
      getReviewsThisMonth()
    }
  }, [restaurantCtx.ActiveRestaurant]);

  // useEffect(() => {
  //   const uniqueUsersMap: Dictionary<boolean> = {}

  //   if (Data.length) {
  //     setReturningUsers(Data.reduce((count, row) => {
  //       if (!uniqueUsersMap[row.id]) {
  //         uniqueUsersMap[row.id] = true;
  //         return count + 1
  //       }
  //       else
  //         return count
  //     }, 0) / Data.length)

  //     setAvg(Data.reduce((a, row) => {
  //       return a + row.total
  //     }, 0) / Data.length)
  //     setAmount(Data.length);
  //   }

  // }, [Data]);

  useEffect(() => {
    if (restaurantCtx.ActiveRestaurant)
    getReviewsThisMonth();
  }, [restaurantCtx.ActiveRestaurant]);

  const textStyle = {
    marginLeft: 10,
    marginRight: 10,
  }
  return (<div style={{
    display: 'flex',
    flexDirection: 'row'
  }}>

    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: 10,
      marginRight: 10,
      justifyContent: 'center'
    }}>
      <span>סך המכירות</span>
      <span>גדילה</span>
      <span>דירוג ממוצע</span>

    </div>
    <div style={{
      paddingTop: 10,
      paddingBottom: 10,
      width: 1,
      border: '1px dashed ' + MabaliColors.pink
    }} />
    <div style={{
      marginLeft: 5,
      marginRight: 5,
      display: 'flex',
      flexDirection: 'column'
    }}>
      <span style={textStyle}>₪{total}</span>
      <span style={textStyle}>{totalLastMonth ? (total - totalLastMonth)/ total * 100 : 100}%</span>
      <span style={textStyle}>{AvgReview}</span>
    </div>
  </div>
  )
}

export default SalesThisMonth