import React, { useContext, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom'
import { TableExport } from 'tableexport';
import numeral from 'numeral';
import { RestaurantContext } from '../../RestaurantProvider';
import { ReviewType } from './Review.types';
const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

export type ReportDataType = {
    fullname: string;
    coinsnis: number;
    deliveryavg: number;
    driverfee: number;
    drivertip: number;
    givencoins: number;
    name: string;
    id: string;
    created_at: string;
    orderavg: number;
    orderscount: number;
    orderssum: number;
    phone: number;
    deliverytime: string;
    address: any[]
}

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')



const Reviews = ({ business, driver }: { business?: boolean, driver?: boolean }) => {
    const [TableHeight, setTableHeight] = useState<any>("50vh")
    const restaurantCtx = useContext(RestaurantContext);

    const history = useHistory()

    const [Report, setReport] = useState([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const match = useRouteMatch()
    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    useEffect(() => {
        console.log("match", match)
        if (DOMLoaded) {
            //@ts-ignore
            loadScript('/js/dashboard.js', "dashboard")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)

    const getReviewsReport = () => {

        api.get(`/restaurants/reviews?restaurant=${restaurantCtx.ActiveRestaurant?.id}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}`)
            .then(({ data }) => {
                console.log("got report", data)
                setReport(data)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));


    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [Report, DOMLoaded])

    useEffect(() => {
        if (restaurantCtx.ActiveRestaurant)
            getReviewsReport()

    }, [restaurantCtx.ActiveRestaurant, startDate, endDate])


    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight

        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    let cardTitle = i18n.t("bolepo-reviews")
    let fileName = "bolepo-review"
    let sheetName = "bolepo-review"
    let columnRate = i18n.t("")




    if (business) {
        cardTitle = i18n.t("business-reviews")
        fileName = "business-review"
        sheetName = "business-review"
    }

    if (driver) {
        cardTitle = i18n.t("drivers-reviews")
        fileName = "driver-review"
        sheetName = "driver-review"
        columnRate = i18n.t("driver-rate")
    }




    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="">
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{cardTitle}</h4>
                                <div className="card-description">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker
                                            onChange={(date: Date) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />
                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>
                                        <DatePicker
                                            onChange={(date: Date) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />
                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename : fileName,
                                                sheetname: sheetName
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("customer-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("customer-telephone")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("order-id")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("date-time")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("delivery-time")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">סכום הזמנה<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="">צפייה</th>


                                                    <th className="sortStyle">{i18n.t("review")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("content")}<i className="mdi mdi-chevron-down"></i></th>




                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: ReviewType, index: number) => {

                                                    let evaluation = data.system_evaluation
                                                    let feedback = data.system_feedback

                                                    if (business) {
                                                        evaluation = data.evaluation
                                                        feedback = data.feedback
                                                    }

                                                    if (driver) {
                                                        evaluation = data.delivery_man_evaluation ?? 0
                                                        feedback = data.delivery_man_feedback ?? ""
                                                    }


                                                    if(driver && data.evaluation == 0){
                                                        return null
                                                    }


                                                    return <tr key={index}>
                                                        <td>{data.fullname}</td>
                                                        <td>{data.phone}</td>

                                                        <td>{data.id}</td>
                                                        <td>{dayjs(data.created_at).format("DD/MM/YY HH:mm")}</td>
                                                        <td>{(data.deliveryMinutes) ? data.deliveryTime : i18n.t("order-pending")}</td>

                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>
                                                        <td><button className="btn btn-large btn-primary" onClick={() => history.push("/order/" + data.id)}>{i18n.t("view")}</button></td>
                                                        <td>{evaluation}</td>
                                                        <td>{feedback}</td>

                                                    </tr>
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default Reviews
