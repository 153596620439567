export const Color = {
    green: "#459D72",
    pink: "#D00080",
    bolepoBlue: "#0ebcb3",
    darkenBolepoBlue: "#aed3d1",
    black: "#1A1A1A",
    lighBlack: "#342B2B",
    gray: "#D0D0D0",
    white: "#fff",
    darkGray: "#A0A0A0",
    lightGray: "#E7E7E7",
    red : "red"
}
export const MabaliColors = {
    white: "#fff",
    lightGray: "#F2F2F2",
    gray: '#EAE9F1',
    darkGray: '#969696',
    blue: "#166CDB",
    lightBlue: "#50F0F5",
    darkenLightBlue: "#0d689c",
    pink: "#FF46E1",
    transparent: 'transparent',
    lightPink: "#FF9FCA",
    darkenPink: "#C57DB7",
    purple: "#7236C7",
    darkenPurple: "#8731FF",
    lightPurple: "#9661e0",
    yellow: "#FFD700",
    red: "#F2594B",
    black: "#000000",
    lightBlack: "#272727",
}
MabaliColors.darkenPurple = MabaliColors.pink;
MabaliColors.purple = MabaliColors.pink;
Color.bolepoBlue = MabaliColors.pink;

export const shadow = {
    REGULAR : {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
    },
    FAR : {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,

        elevation: 8,
    },
    DEEP : {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.44,
        shadowRadius: 10.32,

        elevation: 16,
    },
}
// Color.bolepoBlue = "#d60e64"
Color.bolepoBlue = "#0ebcb3"