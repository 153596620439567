import Axios, { AxiosInstance } from 'axios'
declare interface CustomAxiosInstance extends AxiosInstance {
  getMedia?: (media: { url: string }) => string,
  serializeJSON?: (data: any) => string
}
const api: CustomAxiosInstance = Axios.create({
  baseURL: 'https://api.mabali.net'
})
const menual: CustomAxiosInstance = Axios.create({
  baseURL: 'https://menualdev.azurewebsites.net'
})

menual.serializeJSON = (data) => {
  console.log("serializeJSON", data != null)
  return Object.keys(data)
    .map(function (keyName) {
      return (
        encodeURIComponent(keyName) + "=" + encodeURIComponent(data[keyName])
      );
    })
    .join("&");
};

// menual.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     if (config.method == "POST") {
//         config.data = menual.serializeJSON(config.data)
//     }

//     return config;
//   }, function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   });

export const storeJwtToken = (token: string) => {
  localStorage.setItem('@jwtToken2', token)

}

export const clearJwtToken = () => {

  localStorage.setItem('@jwtToken2', "")
  delete api.defaults.headers["Authorization"]

}

export const getJwtToken = () => {
  try {
    const value: string | null = localStorage.getItem('@jwtToken2')
    if (value !== null) {
      // value previously stored
      return value
    }
  } catch (e) {
    // error reading value
    return null
  }
}

export const setAuthorization = (jwtToken: string) => {
  if (jwtToken)
    api.defaults.headers["Authorization"] = "Bearer " + jwtToken
}

//menual.getMedia = 
api.getMedia = (media: { url: string }) => {

  if (!media)
    return ""
  const url = api.defaults.baseURL + media.url
  //console.log("getMedia url = ", url)
  return url;
}

const token = getJwtToken()

if (token) {
  setAuthorization(token)
}


export enum StrapiImageFormatSize {
  large = 'large',
  small = 'small',
  medium = 'medium',
  thumbnail = 'thumbnail',
}

export type StrapiImageFormats = {
  [key in StrapiImageFormatSize]: StrapiImageData
}

export enum StrapiImageMime {
  IMAGE_JPEG = 'image/jpeg',
  VIDEO_MP4 = '"video/mp4"'
}

export type StrapiImageData = {
  "ext": string,
  "hash": string;
  "height": number;
  "mime": StrapiImageMime.IMAGE_JPEG;
  "name": string;
  "path"?: string;
  "size": number;
  "url": string;
  "width": number;
}

export type StrapiImage = {
  "alternativeText": string;
  "caption": string;
  "created_at": string;
  "ext": string;
  formats?: StrapiImageFormats,
  "hash": string;
  "height": number;
  "id": number;
  "mime": StrapiImageMime.IMAGE_JPEG | StrapiImageMime.VIDEO_MP4;
  "name": string;
  "previewUrl"?: string;
  "provider": string;
  "provider_metadata"?: string;
  "size": number;
  "updated_at": Date;
  "url": string;
  "width": number;
}


export type StrapiMedia = StrapiImage

export {
  api,
  menual
}