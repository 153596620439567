import React, { useContext, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { TableExport } from 'tableexport';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral'
import { RestaurantContext } from '../../RestaurantProvider';
const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

type CustomerType = {
    anniversary: null
    avgorder: number;
    user_id: string;
    backtobusiness2: number;
    backtosystem2: number;
    backtosystem5: number;
    backtosystem9: number;
    tranzilacommission: number;
    birthday: Date;
    fullname: string;
    gender: null
    lastorder: { id: 210, total: 52, address: "מפרץ שלמה 48 חולון,מחוז המרכז", user_id: 2, fullname: "עומר לוי" }
    name: "Picante"
    opt_out: null
    orderscount: number;
    phone: string
    restaurant_id: number;
    clientDeliveryFee: number;
    total: number;
    paidWithCoins: boolean;
    isMemberAny: string
}

const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')


const Customer = () => {
    const history = useHistory()
    const [TableHeight, setTableHeight] = useState<any>("50vh")
    const restaurantCtx = useContext(RestaurantContext);

    const [Report, setReport] = useState<any>([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const [GlobalConfig, setGlobalConfig] = useState<any>(null);

    const getGlobalConfig = () => {
        api.get("/global-configs")
            .then(({ data }) => {

                data.totalContributions = 0

                data.totalContributions += data.businessCommission
                data.totalContributions += data.mabaliCommission
                data.totalContributions += data.communityCommission
                setGlobalConfig(data)
            })
    }

    useEffect(() => {
        setDOMLoaded(true)
        getGlobalConfig();
    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            // loadScript('/js/dashboard.js', "dashboard")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)

    const getFinancialReport = () => {
        api.get(`/restaurants/customers?restaurant=${restaurantCtx.ActiveRestaurant?.id}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}`)
            .then(({ data }: { data: CustomerType[] }) => {
                console.log("got report", data)
                const groupedCustomers = data.reduce((acc: any, customer: CustomerType) => {
                    if (!acc[customer.user_id]) {
                        acc[customer.user_id] = customer;
                        acc[customer.user_id].orderscount = 0
                        acc[customer.user_id].total = 0
                        acc[customer.user_id].totalWithCoins = 0
                        acc[customer.user_id].totalWithCash = 0
                        acc[customer.user_id].deliveryFees = 0
                    }

                    acc[customer.user_id].orderscount += 1
                    acc[customer.user_id].total += customer.total
                    acc[customer.user_id].deliveryFees += customer.clientDeliveryFee
                    if (customer.paidWithCoins) {
                        acc[customer.user_id].totalWithCoins += customer.total
                    } else {
                        acc[customer.user_id].totalWithCash += customer.total
                    }

                    return acc
                }, {})
                console.log("groupedCustomers", groupedCustomers)
                const groupedCustomersArray = Object.values(groupedCustomers)

                setReport(groupedCustomersArray)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));


    useEffect(() => {
        if (restaurantCtx.ActiveRestaurant)
            getFinancialReport()

    }, [restaurantCtx.ActiveRestaurant, startDate, endDate])


    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight

        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    let summary = {
        totalOrders: 0,
        totalSpent: 0,
        avgOrder: 0,
        totalCommission: 0,
        totalMabaliCommission: 0,
        totalCommunityCommission: 0,
        totalBusinessCommission: 0,
        totalTranzilaCommission: 0
    }

    console.log('Report', Report)

    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="" style={{

            }}>
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card" style={{

                        }}>
                            <div className="card-body" style={{

                            }}>

                                <h4 className="card-title">{i18n.t("customer-data")}</h4>
                                <button onClick={() => {
                                    history.push("/sms-campaign")
                                }} className='btn btn-primary' style={{
                                    position: 'absolute',
                                    right: 10
                                }}> קמפיין סמס</button>
                                <div className="card-description">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker
                                            onChange={(date: any) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>


                                        <DatePicker
                                            onChange={(date: any) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />


                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename: "customer",
                                                sheetname: "customer"
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>

                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped" style={{

                                        }}>
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("telephone")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("dob")}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{i18n.t("anniversary")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("gender")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("orders-count")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("sum-orders")}<i className="mdi mdi-chevron-down"></i></th>
                                                    {/* <th className="sortStyle">{i18n.t("lastorder")}<i className="mdi mdi-chevron-down"></i></th> */}
                                                    <th className="sortStyle">{i18n.t("avgorder")}<i className="mdi mdi-chevron-down"></i></th>


                                                    <th className="sortStyle">{i18n.t("marketing")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Total spent (Coins)<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Total spent (Cash)<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">Delivery Fee<i className="mdi mdi-chevron-down"></i></th>
                                                    {/* <th className="sortStyle">{i18n.t("is-any-memberclub")}<i className="mdi mdi-chevron-down"></i></th> */}


                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: any, index: number) => {

                                                    summary.totalOrders += parseInt(data.orderscount)
                                                    summary.totalCommission += data.backtosystem9
                                                    summary.totalMabaliCommission += data.backtosystem5
                                                    summary.totalCommunityCommission += data.backtosystem2
                                                    summary.totalBusinessCommission += data.backtobusiness2
                                                    summary.totalTranzilaCommission += data.tranzilacommission
                                                    summary.avgOrder += (data.avgorder)
                                                    summary.totalSpent += (data.total)



                                                    return <tr key={index}>
                                                        <td>{data.fullname}</td>
                                                        <td>{data.phone}</td>
                                                        <td>{dayjs(data.birthday).format("DD/MM/YYYY")}</td>
                                                        <td>{data.anniversary ? dayjs(data.anniversary!).format("DD/MM/YYYY") : ""}</td>
                                                        <td>{i18n.t(data.gender)}</td>
                                                        <td>{data.orderscount}</td>
                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>
                                                        {/* <td>{data.lastorder ? <button className="btn btn-primary" onClick={() => {
                                                            history.push("/order/" + data.lastorder.id)
                                                        }}>{i18n.t("view")}</button> : null}</td> */}
                                                        <td>₪{numeral(data.avgorder).format("0,0.00")}</td>

                                                        <td>{data.opt_out ? <span>✔️</span> : <span>❌</span>}</td>
                                                        <td>₪{numeral(data.totalWithCoins).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.totalWithCash).format("0,0.00")}</td>
                                                        <td>₪{numeral(data.deliveryFees).format("0,0.00")}</td>
                                                        {/* <td>{i18n.t(data.isMemberAny != "0" ? "yes" : "no")}</td> */}


                                                    </tr>
                                                })}
                                                <tr style={{
                                                    fontWeight: 800
                                                }} >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{summary.totalOrders}</td>
                                                    <td>₪{numeral(summary.totalSpent).format("0,0.00")}</td>
                                                    <td></td>
                                                    <td>₪{numeral(summary.avgOrder / Report.length).format("0,0.00")}</td>

                                                    <td></td>
                                                    <td></td>


                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default Customer
