import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../configureStore'
import i18n from '../../../i18n'
import { fetchRestaurantClubMembers } from '../../../redux/smsCampaignSlice'
import { api } from '../../../utils/api'
import CampaignStepper from '../components/CampaignStepper'
import MembersTable from '../components/MembersTable'
import MessageContentForm from '../components/MessageContentForm'
import { MemberClubType, SMSCampaignType } from '../components/types'

const CreateSmsCampaign = () => {
    const [stepIndex, setStepIndex] = useState(0);
    const [progressValue, setProgressValue] = useState(0);
    const [clubMembers, setClubMembers] = useState<Array<MemberClubType>>([]);
    const MembersClubArray = useSelector((state: RootState) => state.smsCampaign.MembersClub)
    const [selectedMembers, setSelectedMembers] = useState<Array<Number>>([]);
    const [messageContent, setMessageContent] = useState<string>("");
    const [campaignCost, setCampaignCost] = useState("");
    const dispatch = useDispatch()
    const [businessId, setBusinessId] = useState("");
    const auth = useSelector((state: RootState) => state.auth)
    const [SelectedBusiness, setSelectedBusiness] = useState<string>("")
    const CurrentBusinessID = useSelector((state: RootState) => state.smsCampaign.CurrentBusinessID)
    const [creatingCampaign, setCreatingCampaign] = useState(false);
    const [loadingCampaign, setloadingCampaign] = useState(false);
    const [smsCampaign, setSmsCampaign] = useState<SMSCampaignType>()
    useEffect(() => {
        if (smsCampaign && smsCampaign.message)
            setMessageContent(smsCampaign.message)
    }, [smsCampaign])
    const createSMSCampaign = () => {
        setCreatingCampaign(true)
        api.post("/sms-campaigns", { message: messageContent, clubmembers_target: selectedMembers, restaurant: CurrentBusinessID })
            .then(({ data }) => {
                console.log("Campaign", data);
                setCreatingCampaign(false)
                setSmsCampaign(data)
                setStepIndex(2)
            }).catch((err) => {
                setCreatingCampaign(false)
                console.log(err)
            })
    }
    const testSMSCampaign = () => {
        setloadingCampaign(true)
        if (!smsCampaign) {
            return console.log("Not campaign yet");
        }
        api.post("/membersclubs/test-sms", { campaign: smsCampaign })
            .then(({ data }) => {
                console.log("Campaign", data);
                setloadingCampaign(false)
                setSmsCampaign(data)
            }).catch((err) => {
                setloadingCampaign(false)
                console.log(err)
            })
    }
    const startSMSCampaign = () => {
        setloadingCampaign(true)
        if (!smsCampaign) {
            return console.log("Not campaign yet");
        }
        api.post("/membersclubs/start-sms-campaign", { campaign: smsCampaign })
            .then(({ data }) => {
                console.log("Campaign", data);
                setloadingCampaign(false)
                setSmsCampaign(data)
            }).catch((err) => {
                setloadingCampaign(false)
                console.log(err)
            })
    }
    const onCheckMember = ({ memberId, clearSelection = false }: { memberId: number, clearSelection: boolean | null }) => {
        console.log("Check", memberId);
        if (clearSelection) {
            setSelectedMembers([])
            return
        }
        console.log("Member id", memberId);

        const index = selectedMembers.indexOf(memberId);
        if (index < 0) {
            selectedMembers.push(memberId);
        } else {
            selectedMembers.splice(index, 1);
        }
        setSelectedMembers([...selectedMembers]);
        // console.log("ckeck:", selected, selectedTopics);
        console.log("selectedMembers", selectedMembers);
    };
    const onCheckAllMembers = () => {
        let allIds = [];
        if (selectedMembers.length == MembersClubArray.length) {
            setSelectedMembers([]);
            return;
        }
        //@ts-ignore
        allIds = MembersClubArray.map((mb) => mb.id);
        setSelectedMembers(allIds);
    };
    // async function fetchRestaurantClubMembers({ restaurantId }: { restaurantId: number }) {
    //     let responseData = await api.get(`membersclubs?restaurant.id=${restaurantId}`)
    //     let clubmembers = responseData?.data
    //     // console.log("clubmembers", clubmembers);
    //     setClubMembers(clubmembers)
    // }
    useEffect(() => {
        setProgressValue((stepIndex) * 33)
    }, [stepIndex])
    const renderMemberTable = () => <MembersTable
        setStep={(index: any) => { setStepIndex(index) }}
        onCheckAllMembers={() => onCheckAllMembers()}
        selectedMembers={selectedMembers}
        onCheckMember={({ memberId, clearSelection }) => onCheckMember({ memberId, clearSelection })}
        clubMembers={clubMembers}
    />
    const renderStep = (stepIndex: number) => {
        switch (stepIndex) {
            case 0:
                return renderMemberTable()
            case 1:
                return <MessageContentForm
                    testSMSCampaign={testSMSCampaign}
                    startSMSCampaign={startSMSCampaign}
                    creatingCampaign={creatingCampaign}
                    smsCampaign={smsCampaign}
                    messageContent={messageContent}
                    setMessageContent={(text) => setMessageContent(text)}
                    createSMSCampaign={createSMSCampaign}
                    setStep={(index: any) => {
                        setStepIndex(index)
                    }}
                    stepIndex={stepIndex}
                    enableStartCampaign={false}
                />
            case 2:
                return <MessageContentForm
                    testSMSCampaign={testSMSCampaign}
                    startSMSCampaign={startSMSCampaign}
                    creatingCampaign={creatingCampaign}
                    smsCampaign={smsCampaign}
                    messageContent={messageContent}
                    setMessageContent={(text) => setMessageContent(text)}
                    createSMSCampaign={createSMSCampaign}
                    setStep={(index: any) => {
                        setStepIndex(index)
                    }}
                    stepIndex={stepIndex}
                    enableStartCampaign
                />
            case 3:
                return <div className="row">
                    <div className="col-12 text-center">
                        <h1 className="text-success mt-5">{i18n.t("campaign-sent-success")}</h1>
                    </div>
                    <div className="col-12 text-center">
                        <i className="bi bi-check-circle text-success mt-5"></i>
                    </div>
                </div>
            default: return renderMemberTable()
        }
    }
    return (
        <div>
            <CampaignStepper
                smsCampaign={smsCampaign}
                selectedMembers={selectedMembers}
                setStep={(index: any) => {
                    setStepIndex(index)
                }}
                progressValue={progressValue}
            />
            {renderStep(stepIndex)}
        </div>

    )
}

export default CreateSmsCampaign
