import React, { useContext, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral'
import { RestaurantContext } from '../../RestaurantProvider';
import { OrderDataType } from '../OrdersFinancial/orders-financial.types';

let throttleTimeout: any = null

const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}


const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const Refunds = () => {
    const history = useHistory()
    const [TableHeight, setTableHeight] = useState<any>("50vh")
    const restaurantCtx = useContext(RestaurantContext);
    const [SearchTxt, setSearchTxt] = useState("")

    const [Report, setReport] = useState<any[]>([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    useEffect(() => {
        setDOMLoaded(true)

    }, [])


    const auth = useSelector((state: RootState) => state.auth)

    const getFinancialReport = () => {
        api.get(`/orders?restaurant=${restaurantCtx.ActiveRestaurant?.id}&name_contains=${SearchTxt}&_sort=id:DESC`)
            .then(({ data }) => {
                console.log("got report", data)
                setReport(data)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

  

    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [Report, DOMLoaded])

    useEffect(() => {
        if (restaurantCtx.ActiveRestaurant)
            getFinancialReport()

    }, [restaurantCtx.ActiveRestaurant, startDate, endDate])

    useEffect(() => {

        let cardTitle = 0//document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = 0//document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight
        console.log("cardBody", cardBody)
        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    useEffect(() => {
        if (SearchTxt) {
            if (throttleTimeout)
                clearTimeout(throttleTimeout)

            throttleTimeout = setTimeout(() => getFinancialReport(), 1000)
        }

    }, [SearchTxt])

    const onSearch = (e: any) => {
        setSearchTxt(e.target.value)
    }


    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="">
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-description">
                                    <div className="row">
                                       
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>חיפוש לפי שם</label>
                                                <input type={"text"} onChange={onSearch} className="form-control" placeholder="" />
                                            </div>
                                        </div>

                                    </div>


                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("customer-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("order-system-id")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("order-status")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("datetime")}<i className="mdi mdi-chevron-down"></i></th>
                                                    
                                                    <th className="sortStyle">{i18n.t("customer-telephone")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("customer-address")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">צפייה<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{'סכום הזמנה'}<i className="mdi mdi-chevron-down"></i></th>

                                                   
                                                    <th className="sortStyle">{i18n.t("delivery-time")}<i className="mdi mdi-chevron-down"></i></th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: OrderDataType, index: number) => {
                                                    return <tr key={index}>
                                                        <td>{data.fullname}</td>
                                                        <td>{data.id}</td>
                                                        <td> {i18n.t("status-" + data?.status)}</td>
                                                        <td>{data.created_at ? dayjs(data.created_at).format("DD/MM/YY HH:mm") : null}</td>
                                                        
                                                        <td>{data.phone}</td>
                                                        <td>{data.address}</td>
                                                        <td><button className="btn btn-large btn-primary" onClick={() => history.push("/compensation/" + data.id)}>{i18n.t("view")}</button></td>

                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>

                                                        <td>{data.deliveryTime ? dayjs(data.deliveryTime).format("DD/MM/YY HH:mm") : null}</td>
                                                        


                                                    </tr>
                                                })}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default Refunds
