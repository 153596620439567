
//@ts-nocheck
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Provider } from 'react-redux'
import store from './configureStore'

import PrivateRoutes from './containers/PrivateRoutes';

import Main from './pages/Main'
import Login from './pages/Login';
import Customer from './pages/Customer';
import Orders from './pages/Orders';
import Order from './pages/Order';
import Reviews from './pages/Reviews';
import TopBar from './containers/TopBar'
import dayjs from 'dayjs'
import Refunds from "./pages/Refunds";
import OrderCompensation from "./pages/OrderCompensation";
import SmsCampaignPage from "./pages/sms-campaign";
import ContextProvider from "./ContextProvider";
import OrdersFinancial from "./pages/OrdersFinancial";
import { ChakraProvider } from '@chakra-ui/react'


require('dayjs/locale/he')
dayjs.locale('he')

String.prototype.format = function () {
  let a = this;
  for (let k in arguments) {
    a = a.replace("{" + k + "}", arguments[k])
  }
  return a
}



function App() {


  return (
    <Provider store={store}>
      <ChakraProvider>
        <ContextProvider>
          <Router>
            <div>

              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <Switch>
                {/* <Route path="/about">
            <About />
          </Route>*/}
                <Route path="/login">
                  <Login />
                </Route>
                <PrivateRoutes >
                  <div className="container-scroller">
                    <TopBar />
                    <Route exact path="/customer">
                      <Customer />
                    </Route>
                    <Route exact path="/financial">
                      <OrdersFinancial />
                    </Route>
                    <Route exact path="/business-reviews">
                      <Reviews business />
                    </Route>
                    <Route exact path="/drivers-reviews">
                      <Reviews driver />
                    </Route>
                    <Route path="/orders">
                      <Orders />
                    </Route>
                    <Route path="/refunds">
                      <Refunds />
                    </Route>
                    <Route path="/order/:id">
                      <Order />
                    </Route>
                    <Route path="/compensation/:id">
                      <OrderCompensation />
                    </Route>
                    <Route path="/sms-campaign">
                      <SmsCampaignPage />
                    </Route>
                    <Route exact path="/">
                      <Main />
                    </Route>
                  </div>
                </PrivateRoutes>
              </Switch>
            </div>
          </Router>
        </ContextProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default App;
