import React from 'react'
import { RestaurantProvider } from './RestaurantProvider'


const ContextProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <RestaurantProvider>
        {children}
    </RestaurantProvider>
  )
}

export default ContextProvider