import React, { useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { useHistory, useParams } from 'react-router-dom';
import Order from '.';
import { setGlobalConfig } from '../../redux/globalSlice';

const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

export type OrderDetailType = {
    "id": 198,
    "verificationCode": null,
    "restaurant": RestaurantType,
    "takeout": null,
    "scheduled": null,
    "delivery": true,
    "curbside": null,
    "orderDate": string | null,
    "published_at": "2021-07-04T19:06:21.349Z",
    "created_at": "2021-07-04T19:06:21.367Z",
    "updated_at": "2021-07-05T06:42:24.822Z",
    "paidWithCoins": false,
    "membersclub_balance_history": null,
    "paid": true,
    "name": "Pablo Zarate",
    "address": null,
    "phone": "+523123170749",
    "total": 21,
    "status": "delivered",
    "users_permissions_user": {
        "id": 14,
        "username": "pablozarateayala@gmail.com",
        "email": "pablozarateayala@gmail.com",
        "provider": "local",
        "confirmed": true,
        "blocked": false,
        "role": 1,
        "created_at": "2021-05-17T10:26:22.904Z",
        "updated_at": "2021-06-13T20:51:08.223Z",
        "phone": "+523123170749",
        "gender": "",
        "birthday": "2001-03-20",
        "firstName": "Pablo",
        "lastName": "Zarate",
        "businessManager": null,
        "driverManager": null,
        "avatar": null
    },
    "preparationTime": string | null,
    "readyTime": string | null,
    "review": {
        "id": 56,
        "evaluation": 5,
        "feedback": "A",
        "published_at": null,
        "created_at": "2021-07-04T19:23:54.671Z",
        "updated_at": "2021-07-05T06:46:04.795Z",
        "daft": null,
        "restaurant": 38,
        "order": 198,
        "system_evaluation": 5,
        "delivery_man_evaluation": 5,
        "delivery_man_feedback": "C",
        "user": 14,
        "draft": false,
        "system_feedback": "B",
        "isDelivery": null
    },
    "pickup": false,
    "dismissed": false,
    "oderType": null,
    "scheduledDate": string | null,
    "deliveryAddressText": "Colima ST null,null",
    "deliveryAddress": null,
    "coinsFunded": false,
    "housedistancetime": null,
    "deliveryTime": string | null,
    "bolepoDriver": false,
    "tip": null,
    "driverFee": null
}

const OrderCompensation = () => {
    const history = useHistory();
    const [SelectedBusiness, setSelectedBusiness] = useState<string>("")
    const params: any = useParams()
    const [Orderdetail, setOrderdetail] = useState<OrderDetailType | any>(null)
    const [Items, setItems] = useState([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const [Compensations, setCompensations] = useState([])
    const [Refunds, setRefunds] = useState([])
    const [CompensationAmount, setCompensationAmount] = useState(0)

    const dispatch = useDispatch()

    const getGlobalConfigs = () => {
        console.log("api get - ", api.defaults.baseURL + "/global-configs ")
        console.log("api ===>", api.defaults.headers)
        api.get("/global-configs")
            .then(({ data }) => {
                //console.log("got globals", data)
                dispatch(setGlobalConfig(data))
            })
            .catch((e) => {
                console.warn("Could not get config", e)
            })
    }

    const [Compensate, setCompensate] = useState(false)
    useEffect(() => {
        setDOMLoaded(true)
        getGlobalConfigs()
    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            // loadScript('/js/dashboard.js', "dashboard")
            loadScript('/vendors/js/vendor.bundle.base.js', "bundle.base")
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)
    const global = useSelector((state : RootState) => state.global)

    useEffect(() => {

        getOrderDetails()
    }, [])

    const getOrderDetails = () => {
        api.get(`/orders/${params.id}`)
            .then(({ data }) => {
                console.log("order details", data)
                setOrderdetail(data)
                getOrderItems()
            })
            .catch((e) => {
                console.log("could not get order detail", e)
            })
    }
    const getOrderItems = () => {
        api.get(`/order-items?order=${params.id}`)
            .then(({ data }) => {
                console.log("order items details", data)
                setItems(data)
            })
            .catch((e) => {
                console.log("could not get order detail", e)
            })
    }
    const getOrderCompensation = () => {
        api.get(`/order-compensations?order=${params.id}`)
            .then(({ data }) => {
                console.log("order items details", data)
                setCompensations(data)
            })
            .catch((e) => {
                console.log("could not get order detail", e)
            })
    }
    const getOrderRefunds = () => {
        api.get(`/order-refunds?order=${params.id}`)
            .then(({ data }) => {
                console.log("order items details", data)
                setRefunds(data)
            })
            .catch((e) => {
                console.log("could not get order detail", e)
            })
    }

    const PrintElem = (e: any) => {
        e.preventDefault()
        //const mywindow = window.open('', 'PRINT', 'height=400,width=600');

        // if (mywindow) {
        // mywindow.document.write('<html><head><title>' + document.title + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title + '</h1>');
        // const printable = mywindow.document.getElementById("order-main")
        // if(printable){
        //     mywindow.document.write(printable.innerHTML);
        // }

        // mywindow.document.write('</body></html>');

        // mywindow.document.close(); // necessary for IE >= 10
        // mywindow.focus(); // necessary for IE >= 10*/

        window.print();
        //    mywindow.close();
        // }


        return true;

    }

    const doRefund = () => {
        if (!Orderdetail) {
            return;
        }

        let totalCoins = Orderdetail.total / global.conversion

        if (window.confirm(i18n.t("confirm-dialog-refund").format(totalCoins))) {
            api.post("/order-refunds", {
                order: Orderdetail.id,
                total: Orderdetail.total 
            })
                .then(({ data }) => {
                    getOrderDetails()
                })
                .catch((e) => {
                    console.warn("could not create refund")
                })
        }
    }

    const compensateAmount = () => {
        if (window.confirm(i18n.t("confirm-dialog-compensate").format(CompensationAmount))) {
            api.post("/order-compensations", {
                order: Orderdetail.id,
                amount: CompensationAmount
            })
                .then(({ data }) => {
                    getOrderDetails()
                })
                .catch((e) => {
                    console.warn("could not create refund")
                })
        }
    }




    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
        getOrderCompensation()
        getOrderRefunds()
    }, [DOMLoaded])

    if (!Orderdetail) {
        return <div className="container-fluid page-body-wrapper">
            <div className="square-box-loader">
                <div className="square-box-loader-container">
                    <div className="square-box-loader-corner-top"></div>
                    <div className="square-box-loader-corner-bottom"></div>
                </div>
                <div className="square-box-loader-square"></div>
            </div>
        </div>
    }



    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="content-wrapper" id="order-main">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card px-2">
                            <div className="card-body">
                                <div className="container-fluid" style={{
                                    
                                    display : 'flex'
                                }}>
                                    <button onClick={() => history.goBack()} className="btn" style={{
                                        padding: 10,
                                        cursor: 'pointer',
                                        backgroundColor: 'transparent'
                                    }}>
                                        <img src={"/images/outline_arrow_back_black_24dp.png"} />
                                    </button>

                                    <h3 style={{
                                        marginTop : 0,
                                        marginBottom : 0
                                    }} className="text-right my-5">#{Orderdetail.id}</h3>
                                    
                                </div>
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 pl-0">
                                        <p className="mt-5 mb-2"><b>{Orderdetail.users_permissions_user.firstName + " " + Orderdetail.users_permissions_user.lastName}</b></p>
                                        <p>{Orderdetail.deliveryAddressText}</p>
                                    </div>
                                    <div className="col-lg-3 pr-0" >
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}>
                                            <img src={api.getMedia!(Orderdetail.restaurant.logo)} alt="business-logo" style={{ display: 'flex', alignSelf: 'flex-end', width: 100 }} />
                                        </div>
                                        <p className="mt-2 mb-2 text-right"><b>{
                                            //@ts-ignore
                                            Orderdetail.restaurant["name_" + i18n.getLang()] || Orderdetail.restaurant.name
                                        }</b></p>
                                        <p className="text-right">{Orderdetail.restaurant.address}</p>
                                        <p className="text-right">{Orderdetail.restaurant.telephone}</p>
                                    </div>
                                </div>
                                <div className="container-fluid d-flex justify-content-between">
                                    <div className="col-lg-3 pl-0">
                                        <p className="mb-0 mt-5">{i18n.t("date-time")} {Orderdetail.created_at ? dayjs(Orderdetail.created_at).format("DD/MM/YY HH:mm") : null}</p>
                                        <p>{i18n.t("ready-time")} {Orderdetail.readyTime ? dayjs(Orderdetail.readyTime).format("DD/MM/YY HH:mm") : null}</p>
                                        <p>{i18n.t("delivery-time")} {Orderdetail.deliveryTime ? dayjs(Orderdetail.deliveryTime).format("DD/MM/YY HH:mm") : null}</p>
                                    </div>
                                </div>
                                <div className="container-fluid mt-5 d-flex justify-content-center w-100" style={{
                                    flexDirection: "column",
                                    display: 'flex'
                                }}>

                                    <div style={{
                                        flexDirection : "row",
                                        display : 'flex',
                                        justifyContent : 'center',
                                        alignItems : 'center'
                                    }}>
                                    <h1>{i18n.t("order-status")}</h1>
                                    {" - "}
                                    <h2 style={{
                                        fontWeight : 300,
                                        
                                    }}>{" "}{i18n.t("status-" + Orderdetail?.status)}</h2>
                                    </div>
                                    <h5>{i18n.t("refunds")}</h5>
                                    <button disabled={Orderdetail.refunded} onClick={doRefund} className="btn btn-primary" >{i18n.t("refund-btn")}</button>

                                   

                                    <h5>{i18n.t("compensation")}</h5>
                                    <button className="btn btn-warning" disabled={Orderdetail.compensated} onClick={() => setCompensate(state => !state)} >{i18n.t("compensate-btn")}</button>

                                    {Compensate && !Orderdetail.compensated && <div>
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">{i18n.t("amount")}</label>
                                            <input type="number" onChange={(e) => setCompensationAmount(parseFloat(e.target.value))} className="form-control" placeholder="" />
                                        </div>

                                        <button disabled={!CompensationAmount || CompensationAmount == 0} onClick={compensateAmount} className="btn btn-success">{i18n.t("save")}</button>
                                       
                                    </div>}

                                </div>

                                <div className="container-fluid mt-5 w-100">
                                    <p className="text-right mb-2">{i18n.t("subtotal")} ₪{Orderdetail.total}</p>

                                    <h4 className="text-right mb-5">{i18n.t("total")} ₪{Orderdetail.total}</h4>
                                    <hr />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="w-100 clearfix">
                    {/* <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2018 <a href="http://www.bootstrapdash.com/" target="_blank">Bootstrapdash</a>. All rights reserved.</span>
                    <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart-outline text-danger"></i></span> */}
                </div>
            </footer>
        </div>
    </div>
    )
}

export default OrderCompensation
