import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory
} from "react-router-dom";
import { logout, setUserData } from '../redux/authSlice';
import { api, getJwtToken, setAuthorization, clearJwtToken } from '../utils/api';

const PrivateRoutes = ({ children, ...rest }: { children: any, path: string, exact?: any }) => {
  

    const location = useLocation()
    const dispatch = useDispatch()
    const history = useHistory()
    const match = useRouteMatch(rest.path)
    let child = children

    let token = getJwtToken()

    if (!token) {
        console.log("redirecting to login")
        child = (
            <Redirect
                to={{
                    pathname: '/login',
                    state: { from: location }
                }}
            />
        )
    } else {
        setAuthorization(token)

    }



    useEffect(() => {
        if (token)
            api.get('/users/me')
                .then(({ data }) => {
                    console.log("got me", data)
                    dispatch(setUserData({user : data, jwt : token!}))
                })
                .catch((e) => {
                    console.warn("could not get me", e)
                    clearJwtToken()
                    dispatch(logout())
                    history.replace("/login")
                })
    }, [dispatch, history, token])

    child = React.cloneElement(child, {
        match
    })

    return <Route {...rest}>
        {child}
    </Route>
}

export default PrivateRoutes
