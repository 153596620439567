import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../configureStore'
import { DATE_FORMAT } from '../../DateUtil'
import i18n from '../../i18n'
import { RestaurantContext } from '../../RestaurantProvider'
import { api } from '../../utils/api'


export type SaleReportType = {
  created_at: Date;
  total: number;
}

const SalesChart = () => {

  const restaurantCtx = useContext(RestaurantContext);
  const [Sales, setSales] = useState<SaleReportType[]>([])
  const auth = useSelector((state: RootState) => state.auth)
  const [SelectedBusiness, setSelectedBusiness] = useState<string>("")



  const getData = () => {
    api.get("/restaurants/sales-business?start=" + dayjs().format(DATE_FORMAT) + "&restaurants=" + restaurantCtx.ActiveRestaurant?.id)
      .then(({ data }: { data: SaleReportType[] }) => {
        console.log('SalesChart data ==>', data)
        if (data.length) {
          setSales(data)
        }


      })
      .catch((e) => {
        console.warn("could not get sales", e)
      })
  }

  useEffect(() => {

    if (Sales.length == 0) {
      return;
    }

    console.log("Sales Report", Sales)
    // @ts-ignore
    if ($("#sale-report-chart").length) {
      // @ts-ignore
      var CurrentChartCanvas = $("#sale-report-chart").get(0).getContext("2d");
      // @ts-ignore
      var CurrentChart = new Chart(CurrentChartCanvas, {
        type: 'bar',
        data: {
          labels: Sales?.map(row => dayjs(row.created_at).format(DATE_FORMAT)),
          datasets: [{
            label: i18n.t("sales-lbl"),
            data: Sales?.map(row => row.total),
            backgroundColor: ["#3da5f4", "#e0f2ff", "#3da5f4", "#e0f2ff", "#3da5f4", "#e0f2ff", "#3da5f4", "#e0f2ff", "#3da5f4", "#e0f2ff", "#3da5f4"]
          }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          scales: {
            yAxes: [{
              display: true,
              gridLines: {
                drawBorder: false
              },
              ticks: {
                fontColor: "#000",
                display: true,
                padding: 20,
                fontSize: 14,
                stepSize: 10000,
                callback: function (value: any) {
                  var ranges = [
                    { divider: 1e6, suffix: 'M' },
                    { divider: 1e3, suffix: 'k' }
                  ];
                  function formatNumber(n: any) {
                    for (var i = 0; i < ranges.length; i++) {
                      if (n >= ranges[i].divider) {
                        return (n / ranges[i].divider).toString() + ranges[i].suffix;
                      }
                    }
                    return n;
                  }
                  return "₪" + formatNumber(value);
                }
              }
            }],
            xAxes: [{
              stacked: false,
              categoryPercentage: .6,
              ticks: {
                beginAtZero: true,
                fontColor: "#000",
                display: true,
                padding: 20,
                fontSize: 14
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                display: true
              },
              barPercentage: .7
            }]
          },
          legend: {
            display: false
          },
          elements: {
            point: {
              radius: 0
            }
          }
        }
      });
    }
  }, [Sales])

  useEffect(() => {
    if (restaurantCtx.ActiveRestaurant)
      getData()
  }, [restaurantCtx.ActiveRestaurant])

  return (
    <div className="col-sm-9 grid-margin stretch-card">
      <div className="card">
        <div className="row">
          <div className="col-12">
            <div className="card-body h-100 d-flex flex-column" style={{
              position : 'relative'
            }}>
              <p className="card-title">{i18n.t("sales-report")}</p>


              <canvas id="sale-report-chart"></canvas>
              {Sales.length === 0 && <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right : 0,
                bottom : 0,
                display: 'flex',
                justifyContent : 'center',
                alignItems: 'center',
                backgroundColor : '#00000043'
              }}>
                <h1 style={{
                  color : '#fff'
                }}>אין נתונים</h1>
              </div>}
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default SalesChart
