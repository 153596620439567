import React, { useContext, useEffect, useState } from 'react'
import { api } from '../../utils/api';
import dayjs from 'dayjs'
import DatePicker from 'react-date-picker';
import i18n from '../../i18n';
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import { RestaurantType } from '../../redux/restaurantSlice';
import { TableExport } from 'tableexport';
import { useHistory } from 'react-router-dom';
import numeral from 'numeral'
import { getOrderType } from '../OrdersFinancial/OrdersFinancial';
import { RestaurantContext } from '../../RestaurantProvider';
import { OrderDataType } from '../OrdersFinancial/orders-financial.types';

const loadScript = function (src: string, id: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    tag.id = id
    let previousTag = window.document.getElementById(id)
    if (previousTag)
        window.document.getElementsByTagName('body')[0].removeChild(previousTag)
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}


const defaultStartDate = dayjs().add(-30, 'day')
const defaultEndDate = defaultStartDate.add(30, 'day')

const Customer = () => {
    const history = useHistory()
    const [TableHeight, setTableHeight] = useState<any>("50vh")
    const restaurantCtx = useContext(RestaurantContext);
    const [SelectedBusiness, setSelectedBusiness] = useState<string>("")

    const [Report, setReport] = useState<any[]>([])
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const [OrderType, setOrderType] = useState("current")
    const [GlobalConfig, setGlobalConfig] = useState<any>(null);

    const getGlobalConfig = () => {
        api.get("/global-configs")
        .then(({data}) => {
            
            data.totalContributions = 0

            data.totalContributions += data.businessCommission
            data.totalContributions += data.mabaliCommission
            data.totalContributions += data.communityCommission
            setGlobalConfig(data)
        })
    }

    useEffect(() => {
        setDOMLoaded(true)
        getGlobalConfig()
    }, [])

    useEffect(() => {

        if (DOMLoaded) {
            //@ts-ignore
            loadScript('/js/jq.tablesort.js', "tablesort")
            loadScript('/js/tablesorter.js', "tablesorter")
        }
    }, [DOMLoaded])


    const auth = useSelector((state: RootState) => state.auth)

    const getFinancialReport = () => {
        api.get(`/restaurants/orders?restaurant=${restaurantCtx.ActiveRestaurant?.id}&start=${startDate.format("YYYY-MM-DD")}&end=${endDate.format("YYYY-MM-DD")}&type=${OrderType}`)
            .then(({ data }) => {
                console.log("got report", data)
                setReport(data)
            })
            .catch((e) => {
                console.log("could not get financial", e)
            })
    }



    const [startDate, onChangeStartDate] = useState(dayjs().add(-30, 'day'));
    const [endDate, onChangeEndDate] = useState(startDate.add(30, 'day'));

  


    useEffect(() => {
        // if(DOMLoaded)
        //     loadScript('/js/jq.tablesort.js', "tablesort")
        //     loadScript('/js/tablesorter.js', "tablesorter")
    }, [Report, DOMLoaded])

    useEffect(() => {
        if (restaurantCtx.ActiveRestaurant)
            getFinancialReport()

    }, [restaurantCtx.ActiveRestaurant, startDate, endDate, OrderType])

    useEffect(() => {

        let cardTitle = document.getElementsByClassName("card-title")[0].clientHeight
        let cardDescription = document.getElementsByClassName("card-title")[0].clientHeight
        let cardBody = document.getElementsByClassName("card-body")[0].clientHeight
        console.log("cardBody", cardBody)
        setTableHeight((cardBody - cardTitle - cardDescription))

    }, [])

    let TotalOrders = 0

    Report.map((row) => {
             TotalOrders += row.total                               
    })

    let summary = {
        totalSpent: 0,
        totalCommission: 0,
        totalMabaliCommission: 0,
        totalCommunityCommission: 0,
        totalBusinessCommission: 0,
        totalTranzilaCommission: 0,
        mabaliCoins: 0,
        businessCoins: 0
    }


    return (<div className="container-fluid page-body-wrapper">
        <div className="main-panel">
            <div className="">
                <div className="row">

                    <div className="col-12 grid-margin">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">{i18n.t("orders-data")}</h4>
                                <button onClick={() => {
                                    history.push("/refunds")
                                }} className='btn btn-primary' style={{
                                    position: 'absolute',
                                    right : 10
                                }}>זיכוי ופיצוי</button>
                                <div className="card-description">
                                    
                                    <div style={{
                                        display: "flex",
                                        flexDirection: 'row'
                                    }}>

                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("start-date")}</span>
                                        <DatePicker
                                            onChange={(date:any) => {
                                                console.log("change startDate", date)
                                                onChangeStartDate(dayjs(date ? date : defaultStartDate))
                                            }
                                            }
                                            value={startDate.toDate()}
                                        />
                                        <span style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }}>{i18n.t("end-date")}</span>
                                        <DatePicker
                                            onChange={(date:any) => {
                                                console.log("onChange endDate", date)
                                                onChangeEndDate(dayjs(date ? date : defaultEndDate))
                                            }
                                            }
                                            value={endDate.toDate()}
                                        />

                                        <button className="btn btn-success" style={{
                                            marginLeft: 20,
                                            marginRight: 20
                                        }} onClick={() => {

                                            /* Bootstrap classes used to style and position the export button, i.e. when (`bootstrap: true` & `exportButtons: true`) */
                                            TableExport.prototype.bootstrapConfig = ["btn", "btn-primary", "separate"];
                                            //@ts-ignore
                                            new TableExport(document.getElementsByTagName("table"), {
                                                bootstrap: true,
                                                RTL: true,
                                                filename: "orders",
                                                sheetname: "orders"
                                            });
                                        }} ><img src="https://unpkg.com/tableexport/dist/img/xlsx.svg" /> {i18n.t("export-xlsx")}</button>
                                        <h2>₪{numeral(TotalOrders).format('0,0')}</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="" style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: 40,
                                        border: '1px solid #333',
                                        borderRadius : 5

                                    }}>
                                        <button onClick={() => setOrderType("current")} className={"btn " + (OrderType == "current"  ? "btn-primary" : "btn-flat")}>{i18n.t("regular-order")}</button>
                                        <button onClick={() => setOrderType("refunds")} className={"btn " + (OrderType == "refunds"  ? "btn-primary" : "btn-flat")}>{i18n.t("refunds-order")}</button>
                                    </div>
                                </div>
                                <div className="row" style={{
                                    height: TableHeight,
                                    overflowY: "scroll"
                                }}>
                                    <div className="table-sorter-wrapper col-lg-12 table-responsive" style={{
                                        overflowX: 'visible'
                                    }}>
                                        <table id="sortable-table-1" className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="sortStyle">{i18n.t("customer-name")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("date-time")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("order-system-id")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'סוג הזמנה'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{i18n.t("ordersum")}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'סוג שליח'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'תאריך ושעה'}<i className="mdi mdi-chevron-down"></i></th>

                                                    <th className="sortStyle">{'מטבעות עסק ששולמו'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'זמן משלוח'}<i className="mdi mdi-chevron-down"></i></th>
                                                    <th className="sortStyle">{'צפייה בהזמנה'}<i className="mdi mdi-chevron-down"></i></th>


                                                    
                                                  
                                                    

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Report.map((data: OrderDataType, index: number) => {

                                                    summary.totalCommission += data.allCommission
                                                    summary.totalMabaliCommission += data.mabaliCommission
                                                    summary.totalCommunityCommission += data.communityCommission
                                                    summary.totalBusinessCommission += data.businessCommission
                                                    summary.totalTranzilaCommission += data.tranzilaCommission
                                                    summary.totalSpent += (data.total)

                                                    let businessCoins = 0

                                                    data.paymentObj && Object.keys(data.paymentObj).map((key) => {
                                                        if(data.paymentObj){
                                                            let paymentMethod = data.paymentObj[key];
                                                            if(paymentMethod.type == 'coin'){
                                                                businessCoins += paymentMethod.amount;
                                                            }
                                                        }
                                                       
                                                    })

                                                    if(data)
                                                    return <tr key={index}>
                                                        <td>{data.fullname}</td>
                                                        <td>{dayjs(data.orderDate).format('DD/MM/YY HH:mm')}</td>
                                                        <td>{data.id}</td>
                                                        <td>{getOrderType(data.orderType)}</td>
                                                        <td>₪{numeral(data.total).format("0,0.00")}</td>
                                                        <td>{(data.mabaliDriver ? "שליח מהבאלי" : "שליח עסק")}</td>
                                                        <td>{data.created_at ? dayjs(data.created_at).format("DD/MM/YY HH:mm") : null}</td>
                                                        <td>{data.deliveryMinutes ? data.deliveryMinutes : 0}</td>

                                                        <td>{businessCoins}</td>
                                                        <td><button className="btn btn-large btn-primary" onClick={() => history.push("/order/" + data.id + `?membersclub=${data.membersclub}&user_orders=${data.user_orders}`)}>{i18n.t("view")}</button></td>
                                                       
                                                      

                                                    </tr>
                                                })}

                                                {/* <tr style={{
                                                    fontWeight : 800
                                                }} >
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>₪{numeral(summary.totalSpent).format("0,0.00")}</td>
                                                    
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    
                                                    


                                                </tr> */}


                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <style>
            {`
                .separate > button {
                    margin-left : 5px;
                    margin-right : 5px;
                }
            `}
        </style>
    </div>

    )
}

export default Customer
