
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { RootState } from '../configureStore'
import i18n from '../i18n'
import { logout } from '../redux/authSlice'
import { api, clearJwtToken } from '../utils/api'
import Dropdown, { Option } from 'react-dropdown';
import 'react-dropdown/style.css';
import { useContext, useEffect, useState } from 'react'
import { RestaurantType } from '../redux/restaurantSlice'
import { RestaurantContext } from '../RestaurantProvider'
import { MabaliColors } from '../utils/Colors'

type OptionType = {
    label: string;
    value: string;
}
const TopBar = () => {
    const dispatch = useDispatch()
    const auth = useSelector((state: RootState) => state.auth)
    const history = useHistory()
    const [RestaurantOption, setRestaurantOption] = useState<Option[]>([]);
    const [SelectedRestaurant, setSelectedRestaurant] = useState<Option | undefined>();

    const location = useLocation()

    const restaurantCtx = useContext(RestaurantContext);


    useEffect(() => {

        if (auth.user?.restaurants.length) {
            setRestaurantOption(auth.user.restaurants.map((resta) => {
                return {
                    label: resta.name,
                    value: `${resta.id}`,
                }
            }))
            const defaultRestaurant = auth.user.restaurants[0];

            if (defaultRestaurant)
                setSelectedRestaurant({
                    label: defaultRestaurant.name,
                    value: `${defaultRestaurant.id}`
                });
        }
    }, [auth.user?.restaurants]);

    const doLogout = () => {
        dispatch(logout())
        clearJwtToken()
        history.replace("/login")
    }


    useEffect(() => {

        if (SelectedRestaurant) {
            const restaId = parseInt(SelectedRestaurant.value)
            restaurantCtx.setActiveRestaurant(auth.user?.restaurants.find((resta) => resta.id === restaId))
        }
    }, [SelectedRestaurant]);

    if (!auth.user) {
        return null
    }


    const getActiveLink = (path: string) => {

        if (path == location.pathname)
            return " active-link"
        return ""
    }


    const onSelect = (option: Option) => {
        console.log('onSelect', option);
        setSelectedRestaurant(option);
    }

    return (
        <div className="horizontal-menu">
            <nav className="navbar top-navbar col-lg-12 col-12 p-0" style={{
                backgroundColor: MabaliColors.pink
            }}>
                <div className="container">
                    <div className="text-center navbar-brand-wrapper d-flex " style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: 'center',
                        flexDirection: 'row',
                        flex: 1
                    }}>
                        <a className="navbar-brand brand-logo" href="index.html">
                            <img src="/images/appstore.png" alt="logo" style={{
                                borderRadius: 6
                            }} />
                        </a>
                        <h1 className='d-none d-md-block' style={{
                            fontSize: 20,
                            color: "#fff",
                            marginLeft: 10,
                            marginRight: 10
                        }}>ניהול הזמנות</h1>
                        <div className='nav-item d-flex' style={{
                            display: 'flex',
                            // flex: 1,
                            flexDirection: 'column',
                            alignItems: 'center',
                            paddingLeft: 10,
                            paddingRight: 10
                        }}>
                            <Dropdown
                                options={RestaurantOption}
                                onChange={onSelect}
                                value={SelectedRestaurant}
                                placeholder="Select an option" />
                        </div>

                        <div className="navbar-menu-wrapper d-flex " style={{

                            flex: 1
                        }}>
                            <ul className="navbar-nav navbar-nav-right">

                                <li className="nav-item nav-profile dropdown mr-0 mr-sm-2" style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <a className="nav-link dropdown-toggle" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row'
                                    }} href="#" data-toggle="dropdown" id="profileDropdown">
                                        <img style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: 20
                                        }} src={api.getMedia!(auth.user.avatar)} alt="" />

                                        <span className="nav-profile-name">{i18n.t("hello").format(auth.user.firstName)}</span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
                                        {/* <a className="dropdown-item">
                                            <i className="mdi mdi-settings text-primary"></i>
                                            Settings
                                        </a> */}
                                        <a className="dropdown-item" onClick={doLogout}>
                                            <i className="mdi mdi-logout text-primary"></i>
                                            {i18n.t("logout")}
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>






                </div>
            </nav>
            <nav className="bottom-navbar">
                <div className="container">
                    <ul className="nav page-navigation">
                        <li className={"nav-item" + getActiveLink("/")}>
                            <a className={"nav-link"} style={{

                            }} onClick={() => history.push("/")} href="#">
                                <i className="mdi mdi-home-outline menu-icon"></i>
                                <span className="menu-title">{i18n.t("dashboard")}</span>
                            </a>
                        </li>
                        <li className={"nav-item" + getActiveLink("/financial")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/financial")}>
                                <i className="mdi mdi-newspaper menu-icon"></i>
                                <span className="menu-title">{i18n.t("business-financial")}</span>
                            </a>
                        </li>

                        {/* <li className={"nav-item" + getActiveLink("/bolepo-reviews")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/bolepo-reviews")}>
                                <i className="mdi mdi-comment-account-outline menu-icon"></i>
                                <span className="menu-title">{i18n.t("bolepo-reviews")}</span>
                            </a>
                        </li> */}
                        <li className={"nav-item" + getActiveLink("/business-reviews")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/business-reviews")}>
                                <i className="mdi mdi-comment-account menu-icon"></i>
                                <span className="menu-title">{i18n.t("business-reviews")}</span>
                            </a>
                        </li>
                        {/* <li className={"nav-item" + getActiveLink("/drivers-reviews")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/drivers-reviews")}>
                                <i className="mdi mdi-comment-account menu-icon"></i>
                                <span className="menu-title">{i18n.t("drivers-reviews")}</span>
                            </a>
                        </li> */}

                        {/* <li className={"nav-item" + getActiveLink("/sms-campaign")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/sms-campaign")}>
                                <i className="mdi mdi-comment-text-outline menu-icon"></i>
                                <span className="menu-title">{i18n.t("sms-campaign")}</span>
                            </a>
                        </li> */}
                        <li className={"nav-item" + getActiveLink("/customer")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/customer")}>
                                <i className="mdi mdi-account-box menu-icon"></i>
                                <span className="menu-title">{i18n.t("customers")}</span>
                            </a>
                        </li>
                        <li className={"nav-item" + getActiveLink("/orders")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/orders")}>
                                <i className="mdi mdi-codepen menu-icon"></i>
                                <span className="menu-title">{i18n.t("orders")}</span>
                            </a>
                        </li>
                        {/* <li className={"nav-item" + getActiveLink("/refunds")}>
                            <a className="nav-link" href="#" onClick={() => history.push("/refunds")}>
                                <i className="mdi mdi-codepen menu-icon"></i>
                                <span className="menu-title">{i18n.t("refunds")}</span>
                            </a>
                        </li> */}


                    </ul>
                </div>
            </nav>
            <style>
                {`
                    .active-link {
                        border-bottom : 4px solid #405189;
                    }
                    `}
            </style>
        </div>
    )
}

export default TopBar
