import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type RestaurantType = {
  "LocationID": "2fead3b5-ea07-4329-be5f-004d34a8b3f0",
  "address": "",
  "averagePrice": null,
  "averageTime": null,
  "color": "#000000",
  "colorText": "#ffffff",
  "created_at": "2020-12-22T06:04:05.157Z",
  "currency": "$",
  "description": null,
  "facebook": null,
  
  joinCoins : number;
  joinCoinsText_en : string;
  joinCoinsText_he : string;
  isClosed : boolean;
  operation_time : any;
  "featured": [
     {
      "alternativeText": "",
      "caption": "",
      "created_at": "2020-12-14T02:43:25.654Z",
      "ext": ".jpg",
      "formats":  {
        "large":  {
          "ext": ".jpg",
          "hash": "large_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
          "height": 667,
          "mime": "image/jpeg",
          "name": "large_dan-gold-4_jhDO54BYg-unsplash.jpg",
          "path": null,
          "size": 209.19,
          "url": "/uploads/large_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
          "width": 1000,
        },
        "medium":  {
          "ext": ".jpg",
          "hash": "medium_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
          "height": 500,
          "mime": "image/jpeg",
          "name": "medium_dan-gold-4_jhDO54BYg-unsplash.jpg",
          "path": null,
          "size": 129.06,
          "url": "/uploads/medium_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
          "width": 750,
        },
        "small":  {
          "ext": ".jpg",
          "hash": "small_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
          "height": 333,
          "mime": "image/jpeg",
          "name": "small_dan-gold-4_jhDO54BYg-unsplash.jpg",
          "path": null,
          "size": 65.93,
          "url": "/uploads/small_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
          "width": 500,
        },
        "thumbnail":  {
          "ext": ".jpg",
          "hash": "thumbnail_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
          "height": 156,
          "mime": "image/jpeg",
          "name": "thumbnail_dan-gold-4_jhDO54BYg-unsplash.jpg",
          "path": null,
          "size": 17.42,
          "url": "/uploads/thumbnail_dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
          "width": 234,
        },
      },
      "hash": "dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d",
      "height": 2000,
      "id": 1,
      "mime": "image/jpeg",
      "name": "dan-gold-4_jhDO54BYg-unsplash.jpg",
      "previewUrl": null,
      "provider": "local",
      "provider_metadata": null,
      "size": 747.37,
      "updated_at": "2020-12-14T02:43:25.688Z",
      "url": "/uploads/dan_gold_4_jh_DO_54_B_Yg_unsplash_77f99c522d.jpg",
      "width": 3000,
    },
  ],
  "id": 17,
  "instagram": null,
  "kosher": null,
  "lang": null,
  "location":  {
    "city": "New York",
    "created_at": "2020-12-14T01:52:35.128Z",
    "id": 1,
    "latitude": -73.95498,
    "longitude": 40.799922,
    "published_at": "2020-12-14T01:52:40.336Z",
    "state": "NY",
    "updated_at": "2020-12-14T02:06:39.645Z",
  },
  "logo": {
    "alternativeText": null,
      "caption": null,
      "created_at": "2021-03-08T03:47:37.628Z",
      "ext": ".jpg",
      "formats":  {
        "large":  {
          "ext": ".jpg",
          "hash": "large_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9",
          "height": 668,
          "mime": "image/jpeg",
          "name": "large_max-delsid-0geTqSZ76Xg-unsplash.jpg",
          "path": null,
          "size": 65.27,
          "url": "/uploads/large_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9.jpg",
          "width": 1000,
        },
        "medium": {
          "ext": ".jpg",
          "hash": "medium_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9",
          "height": 501,
          "mime": "image/jpeg",
          "name": "medium_max-delsid-0geTqSZ76Xg-unsplash.jpg",
          "path": null,
          "size": 37.48,
          "url": "/uploads/medium_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9.jpg",
          "width": 750,
        },
        "small":  {
          "ext": ".jpg",
          "hash": "small_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9",
          "height": 334,
          "mime": "image/jpeg",
          "name": "small_max-delsid-0geTqSZ76Xg-unsplash.jpg",
          "path": null,
          "size": 19.84,
          "url": "/uploads/small_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9.jpg",
          "width": 500,
        },
        "thumbnail":  {
          "ext": ".jpg",
          "hash": "thumbnail_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9",
          "height": 156,
          "mime": "image/jpeg",
          "name": "thumbnail_max-delsid-0geTqSZ76Xg-unsplash.jpg",
          "path": null,
          "size": 6.67,
          "url": "/uploads/thumbnail_max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9.jpg",
          "width": 234,
        },
      },
      "hash": "max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9",
      "height": 1080,
      "id": 70,
      "mime": "image/jpeg",
      "name": "max-delsid-0geTqSZ76Xg-unsplash.jpg",
      "previewUrl": null,
      "provider": "local",
      "provider_metadata": null,
      "size": 188.78,
      "updated_at": "2021-03-08T03:47:37.628Z",
      "url": "/uploads/max_delsid_0ge_Tq_SZ_76_Xg_unsplash_78a4493ea9.jpg",
      "width": 1617
  },
  "name": string | any,
  "photos": [],
  "published_at": "2020-12-22T06:04:04.793Z",
  "rate": null,
  "restaurant_types": [
     {
      "created_at": "2021-01-01T20:32:13.584Z",
      "id": 4,
      "location": {
        "city": "New York",
        "created_at": "2020-12-14T01:52:35.128Z",
        "id": 1,
        "latitude": -73.95498,
        "longitude": 40.799922,
        "published_at": "2020-12-14T01:52:40.336Z",
        "state": "NY",
        "updated_at": "2020-12-14T02:06:39.645Z",
      },
      "name": "Indian",
      "published_at": "2021-01-01T20:32:15.045Z",
      "updated_at": "2021-03-21T09:23:57.277Z",
    },
  ],
  "tags": [
     {
      "created_at": "2021-01-05T01:03:37.194Z",
      "id": 1,
      "published_at": "2021-01-05T01:03:42.206Z",
      "restaurant": null,
      "updated_at": "2021-01-05T01:03:42.242Z",
      "value": "Taco",
    },
  ],
  "telephone": "",
  "updated_at": "2021-01-10T07:23:53.841Z",
}

const restaurantSlice = createSlice({
    name: 'restaurant',
    initialState: {
       
    },
    reducers: {
        selectRestaurant: (state, action : PayloadAction<RestaurantType>) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the Immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
         // console.log("selectRestaurant", action.payload)
        state = action.payload
        return state
      }
    }
    
})

export const {
  selectRestaurant
} = restaurantSlice.actions

export default restaurantSlice