import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../utils/api';

export type MemberClubType = {}
export type SmsCampaignType = {}
export type FetchMembersClubsType = {
    restaurantId: string | number | null,
}
export type CreateCampaignType = { message: "", clubmembers_target: [] }

export const fetchRestaurantClubMembers = createAsyncThunk(
    'smsCampaign/fetchMemberClubMembers',
    async (params: FetchMembersClubsType) => {
        const response = await api.get(`membersclubs?restaurant.id=${params.restaurantId}`);
        return response.data;
    }
);
export const createSMSCampaign = createAsyncThunk(
    'smsCampaign/createSMSCampaign',
    async (params: CreateCampaignType) => {
        const response = await api.post("/sms-campaigns", params);
        return response.data;
    }
);
const smsCampaignSlice = createSlice({
    name: 'smsCampaign',
    initialState: {
        CurrentBusinessID: null,
        FetchingMembersClub: false,
        MembersClub: [],
        SelectedMembers: [],
        creatingCampaign: false,
        currentCampaign: null
    },
    reducers: {
        setCurrentBusinessID: (state, action) => {
            state.CurrentBusinessID = action.payload
        },
        clearSelectedMembers: (state, action) => {
            state.SelectedMembers = []
        },
        setSelectedMembers: (state, action) => {
            state.SelectedMembers = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRestaurantClubMembers.pending, (state) => {
            state.FetchingMembersClub = true;
        });
        builder.addCase(fetchRestaurantClubMembers.fulfilled, (state, action) => {
            state.FetchingMembersClub = false;
            state.MembersClub = action.payload;
        });
        builder.addCase(fetchRestaurantClubMembers.rejected, (state, action) => {
            state.FetchingMembersClub = false;
        });

        builder.addCase(createSMSCampaign.pending, (state) => {
            state.creatingCampaign = true;
        });
        builder.addCase(createSMSCampaign.fulfilled, (state, action) => {
            state.creatingCampaign = false;
            state.currentCampaign = action.payload
        });
        builder.addCase(createSMSCampaign.rejected, (state, action) => {
            state.creatingCampaign = false;
        });
    }

})



export const {
    setCurrentBusinessID,
    setSelectedMembers,
    clearSelectedMembers
} = smsCampaignSlice.actions

export default smsCampaignSlice