import { createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import i18n from '../i18n';
import { api, setAuthorization, storeJwtToken } from '../utils/api';
import { RestaurantType } from './restaurantSlice';



export type LoginParamsType = {
    identifier: string;
    password: string;
}
export type validatePasswordType = {
    password: string;
}
export type MemberParamsType = {
    username: string;
    email: string;
    password: string;
    phone: string;
    firstName: string;
    lastName: string;
    gender: string;
    birthday: string;
    address: object
}
export const login = createAsyncThunk(
    'auth/login',
    async (params: LoginParamsType) => {
        console.log("authSlice login baseURL", api.defaults.baseURL + '/auth/local');
        console.log("authSlice login params", params);
        const response = await api.post(
            '/auth/local',
            params
        );
        console.log('login auth response', response.data);
        return response.data;
    }
);
export const validatePassword = createAsyncThunk(
    'auth/validatePassword',
    async (params: validatePasswordType) => {
        const response = await api.post(
            'auth/validatePassword',
            params
        );
        console.log('validatePassword response', response.data);
        return response.data;
    }
);
export const delaySaveData = createAsyncThunk(
    'auth/login',
    async () => {

        setTimeout(() => { }, 3000)
    }
);


export type LoginResponseType = {

    "avatar": {
        "alternativeText": "",
        "caption": "",
        "created_at": "2021-02-06T07:54:55.902Z",
        "ext": ".jpeg",
        "formats": {
            "large": {
                "ext": ".jpeg",
                "hash": "large_IMG_6034_bb4a2bd93d",
                "height": 763,
                "mime": "image/jpeg",
                "name": "large_IMG_6034.jpeg",
                "path": null,
                "size": 185.2,
                "url": "/uploads/large_IMG_6034_bb4a2bd93d.jpeg",
                "width": 1000,
            },
            "medium": {
                "ext": ".jpeg",
                "hash": "medium_IMG_6034_bb4a2bd93d",
                "height": 573,
                "mime": "image/jpeg",
                "name": "medium_IMG_6034.jpeg",
                "path": null,
                "size": 115.44,
                "url": "/uploads/medium_IMG_6034_bb4a2bd93d.jpeg",
                "width": 750,
            },
            "small": {
                "ext": ".jpeg",
                "hash": "small_IMG_6034_bb4a2bd93d",
                "height": 382,
                "mime": "image/jpeg",
                "name": "small_IMG_6034.jpeg",
                "path": null,
                "size": 54.4,
                "url": "/uploads/small_IMG_6034_bb4a2bd93d.jpeg",
                "width": 500,
            },
            "thumbnail": {
                "ext": ".jpeg",
                "hash": "thumbnail_IMG_6034_bb4a2bd93d",
                "height": 156,
                "mime": "image/jpeg",
                "name": "thumbnail_IMG_6034.jpeg",
                "path": null,
                "size": 9.19,
                "url": "/uploads/thumbnail_IMG_6034_bb4a2bd93d.jpeg",
                "width": 204,
            },
        },
        "hash": "IMG_6034_bb4a2bd93d",
        "height": 1078,
        "id": 26,
        "mime": "image/jpeg",
        "name": "IMG_6034.jpeg",
        "previewUrl": null,
        "provider": "local",
        "provider_metadata": null,
        "size": 322.3,
        "updated_at": "2021-02-06T07:54:55.944Z",
        "url": "/uploads/IMG_6034_bb4a2bd93d.jpeg",
        "width": 1412,
    },
    "birthday": "1991-01-06",
    "blocked": null,
    "confirmed": true,
    "created_at": "2021-02-09T01:16:44.225Z",
    "email": "cokilopez@hotmail.com",
    "gender": "male",
    "id": 3,
    firstName : string;
    lastName :string;
    "phone": "+523121558080",
    "provider": "local",
    "name": string;
    "restaurants": RestaurantType[],
    "role": {
        "description": "Default role given to authenticated user.",
        "id": 1,
        "name": "Authenticated",
        "type": "authenticated",
    },
    "updated_at": "2021-02-09T01:16:44.256Z",
    "username": "cokilopez@hotmail.com",
}

export const createMember = createAsyncThunk(
    'auth/create',
    async (params: MemberParamsType) => {
        console.log("authSlice createMember baseURL", api.defaults.baseURL + '/auth/local/register');
        console.log("authSlice createMember params", params);
        const response = await api.post(
            '/auth/local/register',
            params
        );

        console.log('create auth response', response.data);

        // const addressResponse = await api.post('addresses', { location: params.address.addressLocation, address: params.address.addressAddress, user: response.data.id })
        //     .then(({ data }) => {
        //         console.log("Address Signup:", data);
        //         return data
        //     }).catch(err => console.log(err));
        return response.data;
    }
);



export type BusinessEnumType = "restaurant" | "shop"

type InitialType = {
    jwt: string | null,
    jwtTemp: string | null,
    validatePasswordLoading: boolean,
    validatePasswordSuccess: boolean,
    validatePasswordError: boolean,
    authenticating: boolean;
    authenticatingTemp: boolean;
    signUp: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Birthday: "",
        Gender: "",
        Password: ""
    },
    creating: boolean;
    creatingTemp: boolean;
    businessType: BusinessEnumType;
    user: LoginResponseType | null;
    userTemp: LoginResponseType | null;
    loginError: string;
    loginSuccess: boolean;
    addresses: [];
}

const initialState: InitialType = {
    jwt: "",
    jwtTemp: "",
    validatePasswordLoading: false,
    validatePasswordSuccess: false,
    validatePasswordError: false,
    authenticating: false,
    authenticatingTemp: false,
    signUp: {
        FirstName: "",
        LastName: "",
        Email: "",
        Phone: "",
        Birthday: "",
        Gender: "",
        Password: ""
    },
    creating: false,
    creatingTemp: false,
    businessType: "restaurant",
    user: null,
    userTemp: null,
    loginError: "",
    loginSuccess: false,
    addresses: []
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setSignUpFirstName: (state, action) => {
            state.signUp.FirstName = action.payload
        },
        setSignUpLastName: (state, action) => {
            state.signUp.LastName = action.payload
        },
        setSignUpEmail: (state, action) => {
            state.signUp.Email = action.payload
        },
        setSignUpPhone: (state, action) => {
            state.signUp.Phone = action.payload
        },
        setSignUpBirthday: (state, action) => {
            state.signUp.Birthday = action.payload
        },
        setSignUpGender: (state, action) => {
            state.signUp.Gender = action.payload
        },
        setSignUpPassword: (state, action) => {
            state.signUp.Password = action.payload
        },
        setUserData: (state, action: PayloadAction<{ jwt: string | null, user: LoginResponseType | null }>) => {
            state.jwt = action.payload.jwt
            state.user = action.payload.user
        },
        logout: (state) => {
            state.jwt = null
            state.user = null
            return state
        },
        setUserDataAfterEdited: (state, action) => {
            state.user = action.payload.user
        },
        setBusinessType: (state, action) => {
            state.businessType = action.payload
        },
        clearLoginError: (state) => {
            state.loginError = ""
        },
        setLoginState: (state, action) => {
            state.authenticating = false;
            state.user = action.payload.user
            state.jwt = action.payload.jwt
            setAuthorization(action.payload.jwt)
            storeJwtToken(action.payload.jwt)
            state.authenticatingTemp = false;
            state.userTemp = null;
            state.jwtTemp = "";
        },
        setSignUpSuccessState: (state, action) => {
            state.creating = false;
            state.jwt = action.payload.jwt
            state.user = action.payload.user
            setAuthorization(action.payload.jwt)
            storeJwtToken(action.payload.jwt)
            state.creatingTemp = false;
            state.userTemp = null;
            state.jwtTemp = "";
        },
        clearValidatePassword: (state, action) => {
            state.validatePasswordLoading = false;
            state.validatePasswordSuccess = false;
            state.validatePasswordError = false;
        }
    },
    extraReducers: (builder) => {
        //Fecth device info
        builder.addCase(login.pending, (state) => {
            state.authenticating = true;
            state.loginError = ""
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.authenticatingTemp = false;
            state.user = action.payload.user;
            state.jwt = action.payload.jwt;
            storeJwtToken(action.payload.jwt)
            setAuthorization(action.payload.jwt)
        });
        builder.addCase(login.rejected, (state, action) => {
            state.authenticating = false;
            console.log("invalid login", action.error.message)
            state.loginError = i18n.t("invalid-login")

        });
        //Validate Password
        builder.addCase(validatePassword.pending, (state) => {
            state.validatePasswordLoading = true;
        });
        builder.addCase(validatePassword.fulfilled, (state, action) => {
            state.validatePasswordLoading = false;
            state.validatePasswordSuccess = action.payload.data;
            state.validatePasswordError = !action.payload.data;
        });
        builder.addCase(validatePassword.rejected, (state, action) => {
            state.validatePasswordLoading = false;
            state.validatePasswordSuccess = false;
            state.validatePasswordError = true;
            console.log("invalid validate pass", action.error.message)
        });
        //Fecth device info
        builder.addCase(createMember.pending, (state) => {
            state.creating = true;
        });
        builder.addCase(createMember.fulfilled, (state, action) => {
            state.creatingTemp = false;
            state.userTemp = action.payload.user;
            state.jwtTemp = action.payload.jwt;
            

        });
        builder.addCase(createMember.rejected, (state, action) => {
            console.log("invalid signup", action.error.message)
            state.creating = false;

        });
    }
})

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
export const validateName = (email: string) => {
    const re = /^[a-z\u0590-\u05fe]+$/i
    return re.test(String(email).toLowerCase());
}

const {
    setSignUpBirthday,
    setSignUpEmail,
    setSignUpFirstName,
    setSignUpLastName,
    setSignUpPhone,
    setSignUpGender,
    setUserData,
    clearLoginError,
    setBusinessType,
    setLoginState,
    setSignUpSuccessState,
    clearValidatePassword,
    setUserDataAfterEdited,
    logout
} = authSlice.actions

export {
    setSignUpBirthday,
    setSignUpEmail,
    setSignUpFirstName,
    setSignUpLastName,
    setSignUpPhone,
    setSignUpGender,
    setUserData,
    clearLoginError,
    setBusinessType,
    setLoginState,
    setSignUpSuccessState,
    clearValidatePassword,
    setUserDataAfterEdited,
    logout
}

export default authSlice