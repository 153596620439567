import React, { useEffect, useState, useRef, useContext } from 'react'
import i18n from '../../i18n';
import { api } from '../../utils/api';
import numeral from 'numeral'
import DistributionChart from './DistributionChart';
import SalesChart from './SalesChart';
import authSlice from '../../redux/authSlice';
import { useSelector } from 'react-redux';
import { RestaurantContext } from '../../RestaurantProvider'
import { RootState } from '../../configureStore';
import TopItems from './TopItems';
import FirstOrder from './FirstOrder';
import ReturningCustomersOrderPct from './ReturningCustomersOrderPct';
import AverageBusinessRating from './AverageBusinessRating';
import SalesThisMonth from './SalesThisMonth';
import OrderRejectionReason from './OrderRejectionReason';
import { MabaliColors } from '../../utils/Colors';
import OrderAndRejections from './OrderAndRejections';

type DashboardType = {
    "res_yesterday": number,
    "res_today": number,
    "count_orders_yesterday": number,
    "count_orders_today": number,
    "orders_today": number, "orders_9_today": number,
    count_orders_canceled_yesterday: number;
    count_orders_canceled_today: number;
    "orders_4_today": number, "orders_5_today": number, "bolepo_yesterday_coins": null,
    "bolepo_coins": number, "business_yesterday_coins": number, "business_coins": 1209,
    "user_entrance_yesterday": number, "user_entrance_today": number, "users_yesterday": number,
    "users_today": number,
    "orders_sent": number
    "menu_entrance": number;
    business_coins_given: number;
    takeout_orders: number;
    delivery_orders: number;
    orders_this_month: number;
    orders_last_month: number;
}

const loadScript = function (src: string) {
    var tag = window.document.createElement('script');
    tag.async = false;
    tag.src = src;
    window.document.getElementsByTagName('body')[0].appendChild(tag);
}

// Hook
function usePrevious(value: any) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}

const validateNumber = (value: any) => {
    if (value == -Infinity || value == Infinity || isNaN(value)) {
        value = 0
    }
    return value
}



const Main = () => {
    const [Dashboard, setDashboard] = useState<DashboardType | null>(null)
    const [DOMLoaded, setDOMLoaded] = useState(false)
    const restaurantCtx = useContext(RestaurantContext);
    const auth = useSelector((state: RootState) => state.auth)
    useEffect(() => {
        setDOMLoaded(true)

    }, [])

    const prevDOMLoaded = usePrevious(Dashboard)

    const getDashboard = () => {

        api.get("/restaurants/dashboard-business?restaurants=" + restaurantCtx.ActiveRestaurant?.id)
            .then(({ data }) => {

                setDashboard(data)
            })
            .catch((e) => {
                console.warn("could not get dashboard", e)
            })
    }

    useEffect(() => {
        if (!prevDOMLoaded && DOMLoaded && Dashboard) {
            //@ts-ignore
            loadScript('/js/dashboard.js')
            buildGraphs()

        }
    }, [prevDOMLoaded, DOMLoaded, Dashboard, restaurantCtx.ActiveRestaurant])

    useEffect(() => {
        if (restaurantCtx.ActiveRestaurant)
            getDashboard()

    }, [restaurantCtx.ActiveRestaurant])

    const getTextColor = (value: number) => {
        return value > 0 ? "text-success" : "text-danger"
    }

    const getArrowDirection = (value: number) => {
        return value > 0 ? " mdi-arrow-up" : " mdi-arrow-down"
    }



    let restaurantChange = 0
    let ordersChange = 0
    let ordersCanceldedChange = 0
    let userEntranceChange = 0
    let userTotal = 0

    if (Dashboard) {
        // restaurantChange = validateNumber((Dashboard.orders_sent - (Dashboard.res_yesterday)) / Dashboard.res_today * 100)
        ordersChange = validateNumber((Dashboard.count_orders_today - (Dashboard.count_orders_yesterday)) / Dashboard.count_orders_today * 100)
        if (ordersChange)
            ordersChange = parseFloat(ordersChange.toFixed(2))

        ordersCanceldedChange = validateNumber((Dashboard.count_orders_canceled_today - (Dashboard.count_orders_canceled_yesterday)) / Dashboard.count_orders_canceled_today * 100)
        if (ordersCanceldedChange)
            ordersCanceldedChange = parseFloat(ordersCanceldedChange.toFixed(2))
        // if (restaurantChange)
        //     restaurantChange = parseFloat(restaurantChange.toFixed(2))
        userEntranceChange = validateNumber((Dashboard.user_entrance_today / (Dashboard.user_entrance_yesterday)))
        userTotal = validateNumber((Dashboard.users_today / (Dashboard.users_yesterday)))

    }

    const buildGraphs = () => {

        // @ts-ignore
        if ($("#total-sales-chart").length) {
            var areaData = {
                labels: ["Mon", "", "Tue", "", "Wed", "", "Thu", "", "Fri", "", "Sat"],
                datasets: [
                    {
                        data: [260000, 200000, 290000, 230000, 200000, 180000, 180000, 360000, 240000, 280000, 180000],
                        backgroundColor: [
                            'rgba(61, 165, 244, .0)'
                        ],
                        borderColor: [
                            'rgb(61, 165, 244)'
                        ],
                        borderWidth: 2,
                        fill: 'origin',
                        label: "services"
                    },
                    {
                        data: [160000, 120000, 175000, 290000, 380000, 210000, 320000, 150000, 310000, 180000, 160000],
                        backgroundColor: [
                            'rgba(241, 83, 110, .0)'
                        ],
                        borderColor: [
                            'rgb(241, 83, 110)'
                        ],
                        borderWidth: 2,
                        fill: 'origin',
                        label: "services"
                    }
                ]
            };
            var areaOptions = {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    filler: {
                        propagate: false
                    }
                },
                scales: {
                    xAxes: [{
                        display: true,
                        ticks: {
                            display: true,
                            padding: 20,
                            fontColor: "#000",
                            fontSize: 14
                        },
                        gridLines: {
                            display: false,
                            drawBorder: false,
                            color: 'transparent',
                            zeroLineColor: '#eeeeee'
                        }
                    }],
                    yAxes: [{
                        display: true,
                        ticks: {
                            display: true,
                            autoSkip: false,
                            maxRotation: 0,
                            // stepSize: 100,
                            fontColor: "#000",
                            fontSize: 14,
                            padding: 18,
                            stepSize: 100000,
                            callback: function (value: any) {
                                var ranges = [
                                    { divider: 1e6, suffix: 'M' },
                                    { divider: 1e3, suffix: 'k' }
                                ];
                                function formatNumber(n: any) {
                                    for (var i = 0; i < ranges.length; i++) {
                                        if (n >= ranges[i].divider) {
                                            return (n / ranges[i].divider).toString() + ranges[i].suffix;
                                        }
                                    }
                                    return n;
                                }
                                return formatNumber(value);
                            }
                        },
                        gridLines: {
                            drawBorder: false
                        }
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: true
                },
                elements: {
                    line: {
                        tension: .37
                    },
                    point: {
                        radius: 0
                    }
                }
            }
            //@ts-ignore
            var revenueChartCanvas = $("#total-sales-chart").get(0).getContext("2d");
            //@ts-ignore
            var revenueChart = new Chart(revenueChartCanvas, {
                type: 'line',
                data: areaData,
                options: areaOptions
            });
        }
        //@ts-ignore
        if ($('#offlineProgress').length) {
            //@ts-ignore
            var bar = new ProgressBar.Circle(offlineProgress, {
                color: '#000',
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 6,
                trailWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: true,
                    style: {
                        color: "#fff",
                        position: 'absolute',
                        left: '40%',
                        top: '50%'
                    }
                },
                svgStyle: {
                    width: '90%'
                },
                from: {
                    color: '#f1536e',
                    width: 6
                },
                to: {
                    color: '#f1536e',
                    width: 6
                },
                // Set default step function for all animate calls
                //@ts-ignore
                step: function (state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    var value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('');
                    } else {
                        circle.setText(value);
                    }

                }
            });

            bar.text.style.fontSize = '1rem';
            console.log("usersChange", userEntranceChange)
            bar.animate(userEntranceChange); // Number from 0.0 to 1.0
        }
        //@ts-ignore
        if ($('#onlineProgress').length) {
            //@ts-ignore
            var bar = new ProgressBar.Circle(onlineProgress, {
                color: '#000',
                // This has to be the same size as the maximum width to
                // prevent clipping
                strokeWidth: 6,
                trailWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: true,
                    style: {
                        color: "#fff",
                        position: 'absolute',
                        left: '40%',
                        top: '50%'
                    }
                },
                svgStyle: {
                    width: '90%'
                },
                from: {
                    color: '#fda006',
                    width: 6
                },
                to: {
                    color: '#fda006',
                    width: 6
                },
                //@ts-ignore
                // Set default step function for all animate calls
                step: function (state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    var value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('');
                    } else {
                        circle.setText(value);
                    }

                }
            });

            bar.text.style.fontSize = '1rem';
            console.log("userTotal", userTotal)
            bar.animate(userTotal); // Number from 0.0 to 1.0
        }
    }




    return (



        <div className="container-fluid page-body-wrapper">
            <div className="main-panel">
                {Dashboard ? <div className="content-wrapper">

                    <div className="row">
                        <div className="col-xl-6 grid-margin">
                            <div className="row">
                                <div className="col-md-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">{'הזמנות שהושלמו היום'}</p>
                                            <div className="d-flex flex-wrap align-items-baseline">
                                                <h2 className="mr-3">{Dashboard.count_orders_today}</h2>
                                                <i className={"mdi  mr-1 " + getTextColor(restaurantChange) + getArrowDirection(restaurantChange)}></i><span><p className={"mb-0 font-weight-medium " + getTextColor(restaurantChange)}>{restaurantChange}%</p></span>
                                            </div>
                                            {/* <p className="mb-0 text-muted">{i18n.t("business-description")}</p> */}
                                        </div>
                                        <canvas id="users-chart"></canvas>
                                    </div>
                                </div>
                                <div className="col-md-6 grid-margin stretch-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-title">{'הזמנות שבוטלו היום'}</p>
                                            <div className="d-flex flex-wrap align-items-baseline">
                                                <h2 className="mr-3">{Dashboard.count_orders_canceled_today}
                                                </h2>
                                                <i className={`mdi ${getArrowDirection(ordersCanceldedChange)} mr-1 ${getTextColor(ordersCanceldedChange)}`} /><span><p className={`mb-0 font-weight-medium`}>{ordersCanceldedChange}%</p></span>
                                            </div>
                                            {/* <p className="mb-0 text-muted">{i18n.t("order-growth")}</p> */}
                                        </div>
                                        <canvas id="projects-chart"></canvas>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-12 stretch-card">
                                   
                                </div>
                            </div> */}
                        </div>
                        <div className="col-xl-6 grid-margin  stretch-card">

                            <div className="card">
                                <div className="card-body">
                                    <p className="card-title">{i18n.t("menu-activity")}</p>
                                    <div className="row">
                                        <div className="col-md-6 stretch-card">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-6">
                                                    <div id="offlineProgress"></div>
                                                </div>
                                                <div className="col-6 pl-0">
                                                    <p className="mb-0">{i18n.t("entered-menu")}</p>
                                                    <h2>{numeral(Dashboard.menu_entrance).format("0,0")}</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 stretch-card mt-4 mt-md-0">
                                            <div className="row d-flex align-items-center">
                                                <div className="col-6">
                                                    <div id="onlineProgress"></div>
                                                </div>
                                                <div className="col-6 pl-0">
                                                    <p className="mb-0">{i18n.t("entered-and-order")}</p>
                                                    <h2>{Dashboard.orders_sent}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-success">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-success icon-rounded-md">
                                            <i className="mdi mdi-arrow-top-right"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{'הכנסות סופיות החודש'}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">₪{numeral(Dashboard.orders_today).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-info">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-info icon-rounded-md">
                                            <i className="mdi mdi-basket"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{'CASHBACK החודש'}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">₪{numeral(Dashboard.business_coins_given).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-danger">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-danger icon-rounded-md">
                                            <i className="mdi mdi-chart-donut-variant"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{'הזמנות טייקאווי'}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">{numeral(Dashboard.takeout_orders).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 grid-margin stretch-card">
                            <div className="card border-0 border-radius-2 bg-warning">
                                <div className="card-body">
                                    <div className="d-flex flex-md-column flex-xl-row flex-wrap  align-items-center justify-content-between">
                                        <div className="icon-rounded-inverse-warning icon-rounded-md">
                                            <i className="mdi mdi-chart-multiline"></i>
                                        </div>
                                        <div className="text-white">
                                            <p className="font-weight-medium mt-md-2 mt-xl-0 text-md-center text-xl-left">{'הזמנות משלוחים'}</p>
                                            <div className="d-flex flex-md-column flex-xl-row flex-wrap align-items-baseline align-items-md-center align-items-xl-baseline">
                                                <h3 className="mb-0 mb-md-1 mb-lg-0 mr-1">{numeral(Dashboard.delivery_orders).format("0,0")}</h3>

                                            </div>
                                            <small className="mb-0">{i18n.t("this-month")}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <SalesChart />
                    </div>

                    <div className="row" style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <TopItems />
                        <div style={{
                            border: '1px solid ' + MabaliColors.pink,
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            paddingTop: 20,
                            paddingBottom: 20
                        }}>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',

                            }}>
                                <span style={{
                                    fontSize: 26,
                                    fontWeight: 700,
                                    color: MabaliColors.pink,
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>לקוחות חדשים</span>
                                <FirstOrder orders={Dashboard.orders_sent} />

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    marginTop: 10
                                }}>
                                    <span style={{
                                        fontSize: 26,
                                        fontWeight: 700,
                                        color: MabaliColors.pink
                                    }}>לקוחות חוזרים</span>
                                    <ReturningCustomersOrderPct orders={Dashboard.orders_sent} />
                                </div>
                                <div style={{
                                    width: "100%",
                                    display: 'flex',
                                    flexDirection : 'row'
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: 10
                                    }}>
                                        <span style={{
                                            fontSize: 26,
                                            fontWeight: 700,
                                            color: MabaliColors.pink
                                        }}>דחיות והפסדים</span>
                                        <OrderAndRejections orders={Dashboard.orders_sent} />
                                    </div>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: 10
                                    }}>
                                        <span style={{
                                            fontSize: 26,
                                            fontWeight: 700,
                                            color: MabaliColors.pink
                                        }}>נתוני החודש</span>
                                        {/* <ReturningCustomersOrderPct orders={Dashboard.orders_sent} /> */}
                                        <SalesThisMonth total={Dashboard.orders_this_month} totalLastMonth={Dashboard.orders_last_month} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div> : null}
                <footer className="footer">
                    <div className="w-100 clearfix">
                        <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Copyright © 2021 Mabali. All rights reserved.</span>
                        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Powered by <i className="mdi mdi-heart-outline text-danger"> <a href="http://www.menual.net" target="_blank">MENUAL</a></i></span>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Main


